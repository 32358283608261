import { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { selectTheme } from '../../theme';

export const StyledSelect = ( {
  options,
  onChange,
  multiSelectTask,
  value,
  containerStyle,
  ...selectProps
} ) => {
  const [ selectedValue, setSelectedValue ] = useState( null );
  const onOptionChange = option => {
    if ( Array.isArray( option ) ) {
      onChange( option.map( opt => opt.value ) );
    } else {
      onChange( option.value );
    }
  };

  const valueOption = useMemo( () => {
    if ( !value || !options ) return undefined;
    if ( Array.isArray( value ) )
      return value.map( val => options.find( option => option.value === val ) );
    return options.find( option => option.value === value );
  }, [ value, options ] );

  useEffect( () => {
    setSelectedValue( valueOption );
  }, [ valueOption ] );

  return (
    <>
      {multiSelectTask ?
        <Select
        options={options}
        defaultValue={value}
        isMulti={multiSelectTask}
        onChange={onOptionChange}
        theme={selectTheme}
        styles={{
          control: base => ( { ...base, height: '100%' } ),
          container: base => ( {
            ...base,
            height: 40,
            width: '100%',
            minWidth: '200px',
            ...( containerStyle || {} )
          } )
        }}
        {...selectProps}
        />
        :
        <Select
        options={options}
        isMulti={multiSelectTask}
        onChange={onOptionChange}
        value={selectedValue}
        theme={selectTheme}
        styles={{
          control: base => ( { ...base, height: '100%' } ),
          container: base => ( {
            ...base,
            height: 40,
            width: '100%',
            minWidth: '200px',
            ...( containerStyle || {} )
          } )
        }}
        {...selectProps}
        />
      }
    </>
  );
};
