import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  calcEventsLengthSum,
  calcWeekEventsLengthSum
} from '../../../../utils/dates/dates';
import { concat } from '../../../../utils/utils';
import styles from '../../OperationsScreen.module.sass';
import { HeaderDay } from './HeaderDay';
import { HeaderTotal } from './HeaderTotal';
import { HeaderWeekLabel } from './HeaderWeekLabel';

export const HeaderRow = ( { week, sticky, time, scrollHorizontal, visible } ) => {
  const { weeksCount, eventsByDate } = useSelector( state => state.operationsPage );

  const weeks = useMemo( () => {
    return Array( weeksCount )
      .fill()
      .map( ( _, weekIndex ) => [
        ...Array( 7 )
          .fill()
          .map( ( _, i ) => ( {
            content: <HeaderDay day={week.plus( { days: i, weeks: weekIndex } )} />
          } ) ),
        { content: <HeaderTotal /> }
      ] );
  }, [ week, weeksCount ] );

  const timeWeeks = useMemo( () => {
    return Array( weeksCount )
      .fill()
      .map( ( _, weekIndex ) => [
        ...Array( 7 )
          .fill()
          .map( ( _, i ) => {
            const isoDate = week.plus( { days: i, week: weekIndex } ).toISODate();
            const events = eventsByDate[isoDate]?.filter(
              event => !!event.JobOffer?.AcceptedEmployee && ![ 'completed-sick-leave', 'failed', 'canceled' ].includes( event.status )
            );
            if ( events ) {
              const length = calcEventsLengthSum( events ).toFormat( 'hh:mm' );
              return {
                content: length === '00:00' ? undefined : length
              };
            }
            return {
              content: undefined
            };
          } ),
        {
          content: calcWeekEventsLengthSum(
            Object.values( eventsByDate )
              .flat()
              .filter( event => !!event.JobOffer?.AcceptedEmployee ),
            week.weekNumber + weekIndex
          ).toFormat( 'hh:mm' )
        }
      ] );
  }, [ week, eventsByDate, weeksCount ] );

  return (
    <>
        {Array( weeksCount )
          .fill()
          .map( ( _, i ) => (
            <HeaderWeekLabel
              weekDate={week.plus( { weeks: i } )}
              key={i}
          />
        ) )}
      <div className={concat( styles['header-tile'], styles['header-tile-main'], styles[sticky] )}>
        {visible && scrollHorizontal === true && <div style={{ top: -20 }}>
          <div className={styles['header-week-bg']}></div>
          {Array( weeksCount )
            .fill()
            .map( ( _, i ) => (
              <HeaderWeekLabel
                weekDate={week.plus( { weeks: i } )}
                key={i}
                top={scrollHorizontal && { top:-20 }}
            />
          ) )}
        </div>}
        Employee
      </div>
      {weeks.map( week =>
        week.map( ( day, index ) => (
          <div
            className={concat( styles['header-tile'], styles[day?.className], styles[sticky] )}
            key={index}
          >
            {day?.content}
          </div>
        ) )
      )}

      <div
        className={concat(
          styles['header-tile'],
          styles['header-tile-main'],
          styles['header-time'],
          styles[time]
        )}
      ></div>
      {timeWeeks.map( week =>
        week.map( ( day, index ) => (
          <div
            className={concat( styles['header-tile'], styles['header-time'], styles[time] )}
            key={index}
          >
            {day?.content}
          </div>
        ) )
      )}
    </>
  );
};
