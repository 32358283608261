import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Spinner } from '../../../../components/Spinner';
import { fieldErrorClass } from '../../../../constants';
import { useInviteJoblinkMutation } from '../../../../store/apis/invitationApi';
import { selectTheme } from '../../../../theme';
import validators from '../../../../utils/validators';

const InviteForm = props => {
  const { role, data, closeModal } = props;
  const [ costCentersOptions, setCostCentersOptions ] = useState( null );
  const [ costCenter, setCostCenter ] = useState( {
    value: 'all',
    label: 'All Cost centers',
  } );
  const [ loading, setLoading ] = useState ( false );
  const [ inviteJoblink, { isSuccess, isError } ] = useInviteJoblinkMutation();

  useEffect( () => {
    setCostCentersOptions( data.map( el => ( { value: el?.id, label: el?.name } ) ) );
    setCostCentersOptions( prev => [ { value: 'all', label: 'All Cost centers' }, ...prev ] );
  }, [ data ] );

  const handleCostCenterChange = selectedOption => setCostCenter( selectedOption );

  return (
    <>
      {loading && (
        <div className='overlay'>
          <Spinner />
        </div>
      )}
      <Formik
        initialValues={{ firstName: '', lastName: '', phone: '', email: '' }}
        onSubmit={( values, { setSubmitting } ) => {
          setLoading( true );
          const data = { ...values, CostCenterId: costCenter.value, class: role };
          inviteJoblink( data )
            .unwrap()
            .finally( () => {
              setSubmitting( false );
              setLoading( false );
            } );
        }}
        validateOnMount={true}
      >
        {( {
          values,
          handleChange,
          errors,
          touched,
          handleSubmit,
          isSubmitting,
          isValid,
        } ) => (
          <Form
            onSubmit={handleSubmit}
            className='form'
          >
            <div className='form-group'>
              <div className='form-row'>
                <label htmlFor='firstName'>First name*</label>
                <Field
                  className={`form-control ${
                    errors.firstName && touched.firstName && fieldErrorClass
                  }`}
                  type='text'
                  id='firstName'
                  name='firstName'
                  validate={validators.required}
                  value={values.firstName}
                  onChange={handleChange}
                />
              </div>
              <div className='form-row'>
                <label htmlFor='lastName'>Last name*</label>
                <Field
                  className={`form-control ${
                    errors.lastName && touched.lastName && fieldErrorClass
                  }`}
                  type='text'
                  id='lastName'
                  name='lastName'
                  validate={validators.required}
                  value={values.lastName}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className='form-group'>
              <div className='form-row'>
                <label htmlFor='phone'>Phone</label>
                <Field
                  className={`form-control ${
                    errors.phone && touched.phone && fieldErrorClass
                  }`}
                  type='phone'
                  id='phone'
                  name='phone'
                  value={values.phone}
                  onChange={handleChange}
                />
              </div>
              <div className='form-row'>
                <label htmlFor='email'>Email*</label>
                <Field
                  className={`form-control ${
                    errors.email && touched.email && fieldErrorClass
                  }`}
                  type='email'
                  id='email'
                  name='email'
                  validate={validators.required}
                  value={values.email}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div>
              <div className='select-box w-100 mb-3'>
                <label>
                  <span>Cost center name*</span>
                  <Select
                    value={costCenter}
                    onChange={handleCostCenterChange}
                    options={costCentersOptions}
                    theme={selectTheme}
                  />
                </label>
              </div>
            </div>
            <div className='form-footer'>
              <div className='form-row mt-4 mb-2'>
                {isSuccess && (
                  <span
                    className='text-danger'
                    style={{ fontSize: 12 }}
                  >
                    Invite sent successfully
                  </span>
                )}
                {isError && (
                  <span
                    className='text-danger'
                    style={{ fontSize: 12 }}
                  >
                    Failed to send invite
                  </span>
                )}
                <button
                  className='btn btn--green'
                  type='submit'
                  disabled={
                    isSubmitting ||
                    !isValid ||
                    isSuccess ||
                    isError ||
                    costCenter.value === 'all'
                  }
                >
                  Send
                </button>
              </div>
              <div className='form-row mt-2 mb-0'>
                <button
                  className='btn btn--default'
                  onClick={() => closeModal()}
                  type='button'
                >
                  Cancel
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default InviteForm;
