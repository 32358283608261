import { mainApi } from './mainApi';

export const companiesApi = mainApi.injectEndpoints( {
  endpoints: build => ( {
    getCompany: build.query( {
      query: id => ( { url: `/companyAccounts/${id}` } ),
      providesTags: ( res, err, arg ) => ( err ? [] : [ { type: 'company', id: arg } ] ),
    } ),
    createLocation: build.mutation( {
      query: fields => ( { url: '/locations', method: 'post', body: fields } ),
      invalidatesTags: ( res, err, arg ) =>
        err ? [] : [ { type: 'company', id: arg.CompanyAccountId }, 'companies' ],
    } ),
    updateCompany: build.mutation( {
      query: ( { id, fields } ) => ( {
        url: `/companyAccounts/${id}`,
        method: 'put',
        body: fields,
      } ),
      invalidatesTags: ( res, err, arg ) =>
        err ? [] : [ { type: 'company', id: arg.id }, 'companies' ],
    } ),
    getCompanies: build.query( {
      query: () => ( { url: '/companyAccounts?Locations=true' } ),
      providesTags: [ 'companies' ],
    } ),
    createCompany: build.mutation( {
      query: fields => ( {
        url: '/companyAccounts',
        method: 'post',
        body: fields,
      } ),
      invalidatesTags: ( res, err ) => ( err ? [] : [ 'companies' ] ),
    } ),
  } ),
} );

export const {
  useGetCompanyQuery,
  useCreateLocationMutation,
  useUpdateCompanyMutation,
  useGetCompaniesQuery,
  useCreateCompanyMutation,
} = companiesApi;
