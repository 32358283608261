import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { DateTimeField } from './DateTimeField';

export const StartTimeField = ( { eventTime } ) => {
  const location = useLocation();

  const { setFieldValue } = useFormikContext();

  useEffect( () => {
    if ( location.state?.date ) {
      const prefilledDateTime = DateTime.fromJSDate( location.state.date );
      const resultDateTime = DateTime.now()
        .set( {
          year: prefilledDateTime.year,
          month: prefilledDateTime.month,
          day: prefilledDateTime.day
        } )
        .plus( { hour: 1 } )
        .startOf( 'hour' );
      setFieldValue( 'startTime', resultDateTime.toJSDate() );
    }
  }, [ location.state ] );

  return (
    <DateTimeField
      name='startTime'
      filterDate={date => {
        const now = new Date();
        const dateTime = DateTime.fromJSDate( date );
        if ( eventTime ) return dateTime.hasSame( now, 'day' ) || dateTime < now;
        return dateTime.hasSame( now, 'day' ) || dateTime > now;
      }}
      className='mr-4'
    />
  );
};
