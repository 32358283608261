import { useSelector } from 'react-redux';
import { useGetEmployeeAvailabilitiesQuery } from '../../../store/apis/employeesApi';

export const EmployeeRowName = ( { availability, name, employee, availabilityType } ) => {
  const { currentWeekStart, weeksCount } = useSelector(
    state => state.operationsPage
  );

  const { data: availabilities } = useGetEmployeeAvailabilitiesQuery( {
    id: employee.id,
    range: {
      start: currentWeekStart.toJSDate().toISOString(),
      end: currentWeekStart
        .plus( { week: weeksCount - 1 } )
        .endOf( 'week' )
        .toJSDate()
        .toISOString()
    }
  } );
  const typeAvailable = availabilities?.filter( item => item.type === 'available' );

  return (
    <>
      <span className={`employee-availability ${availability}`}></span>
      {employee?.SentEmployees?.type === 'rejected' ?
        <s className='employee-name'>{name}</s> :
        <b className='employee-name'>{name}</b>
      }
      <br />
      <div className='employee-info'>
        <h4>Internal Comment</h4>
        <p>{employee.internalComment || ' - '}</p><br />
        {availabilityType === 'partiallyAvailable' &&
          <>
            <h4>Availabile</h4>
            {typeAvailable?.map( ( type ) => (
              <p key={type?.start}>
                {new Date( type?.start ).toLocaleTimeString( 'de-DE', {
                  hour: '2-digit',
                  minute: '2-digit',
                } )} -
                {new Date( type?.end ).toLocaleTimeString( 'de-DE', {
                  hour: '2-digit',
                  minute: '2-digit',
                } )}
                <br />
              </p>
            ) )}
          </>
        }
      </div>
      <small>
        {employee.address_Street}, {employee.address_City}
      </small>
    </>
  );
};
