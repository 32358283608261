import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { TimeField } from './TimeField';

export const EndTimeField = ( { eventTime, autoSelectDate } ) => {
  const { values, setFieldValue } = useFormikContext();
  const [ count, setCount ] = useState( 0 );
  const [ timeDiff, setTimeDiff ] = useState( '' );

  const startTime = values?.startTime;
  const endTime = values?.endTime;
  useEffect( () => {
    if ( autoSelectDate && count === 1 ) {
      setFieldValue( 'endTime', DateTime.fromJSDate( startTime ).plus( { hours: 6 } ).toJSDate()
      );
    }
  }, [ startTime ] );

  useEffect( () => {
    let end = endTime;
    let diff = DateTime.fromJSDate( end ).diff( DateTime.fromJSDate( startTime ), 'hours' ).hours;
    let daysDiff = DateTime.fromJSDate( end ).diff( DateTime.fromJSDate( startTime ), 'days' ).days;
    if ( daysDiff < 0 ) {
      end =  DateTime.fromJSDate( startTime ).set( {
          hours: end.getHours(),
          minutes: end.getMinutes(),
          seconds: end.getSeconds()
        } );

      diff = end.diff( DateTime.fromJSDate( startTime ), 'hours' ).hours;
      if ( diff > 10 || diff < 0 )
        end = end.plus( { days: 1 } );

      end = end.toJSDate();
    } else if (
      end > startTime &&
      end.getDate() !== startTime.getDate() &&
      DateTime.fromJSDate( end ).minus( { day: 1 } ).toJSDate() > startTime
    ) {
      end.setDate( startTime.getDate() );
      if ( end < startTime ) {
        end = DateTime.fromJSDate( end ).plus( { day: 1 } ).toJSDate();
      }
    }

    diff = DateTime.fromJSDate( end ).diff( DateTime.fromJSDate( startTime ), 'hours' ).hours;
    if ( diff > 10 || diff < 0 ) {
      end = DateTime.fromJSDate( startTime ).plus( { hours: 10 } ).toJSDate();
    }
    const formattedDiff =
      DateTime.fromJSDate( endTime ).diff( DateTime.fromJSDate( startTime ),[ 'hours', 'minutes' ] ).toFormat( 'hh:mm' );
    if ( formattedDiff < '00:00' ) {
      setTimeDiff( '06:00' );
    } else {
      setTimeDiff( formattedDiff );
    }

    setFieldValue( 'endTime', end );

  }, [ startTime, endTime ] );

  return (
    <div className='d-flex flex-column'>
      <div className='d-flex align-items-center'>
        <TimeField
          name='startTime'
          setCount={setCount && setCount}
          filterTime={time => {
            if ( eventTime ) return time;
            return time > new Date();
          }}
        />
        <TimeField
          name='endTime'
          filterTime={null}
          className='mr-2'
        />
        <p className='form-row w-100 date-time-field pt-1'> {timeDiff} hrs </p>
      </div>
    </div>
  );
};
