import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import notificationIcon from '../../assets/images/notofication.svg';
import ErrorBoundary from '../../components/ErrorBoundary';
import NoResultInTable from '../../components/NoResultInTable';
import { SearchPanel } from '../../components/SearchPanel';
import { Spinner } from '../../components/Spinner';
import { usePagination } from '../../hooks/usePagination';
import { searchByFirstAndLastName } from '../../store/actions/appActions';
import { useGetCostCentersQuery } from '../../store/apis/costCentersApi';
import { useGetCustomerUsersQuery } from '../../store/apis/customerUsersApi';
import {
  useGetInvitedCustomerUsersQuery,
  useGetInvitedJoblinkUsersQuery
} from '../../store/apis/invitationApi';
import { useGetJoblinkUsersQuery } from '../../store/apis/joblinkUsersApi';
import { InviteCustomerModal } from './InviteCustomerModal';
import InviteModal from './InviteModal';

const Accounts = ( { title, role } ) => {
  const joblinkUsers = useGetJoblinkUsersQuery();
  const customerUsers = useGetCustomerUsersQuery();
  const inviteCU = useGetInvitedCustomerUsersQuery();
  const inviteJU = useGetInvitedJoblinkUsersQuery();
  const { data: costCenters } = useGetCostCentersQuery();

  const [ searchTerm, setSearchTerm ] = useState( '' );

  const isLoading = useMemo( () => {
    return role === 'customerUsers' ? customerUsers.isLoading : joblinkUsers.isLoading;
  }, [ role, joblinkUsers, customerUsers ] );

  const roleData = useMemo( () => {
    const newJoblinkUsers = inviteJU.data
      ? joblinkUsers.data?.concat( inviteJU.data )
      : joblinkUsers.data;

    if ( role === 'admin' )
      return newJoblinkUsers?.filter( user => user.class === 'admin' ) || [];
    else if ( role === 'user' )
      return newJoblinkUsers?.filter( user => user.class === 'user' ) || [];
    else if ( role === 'customerUsers' && customerUsers.data )
      return customerUsers.data?.concat( inviteCU.data || [] ) || [];
    else return [];
  }, [ customerUsers.data, joblinkUsers, role, inviteCU.data, inviteJU.data ] );

  const [ modalIsOpen, setIsOpen ] = useState( false );
  const [ modalData, setModalData ] = useState( null );

  const filteredItems = useMemo(
    () => searchByFirstAndLastName( roleData, searchTerm ),
    [ roleData, searchTerm ]
  );

  const { visibleItems, PaginationComponent } = usePagination( filteredItems );

  useEffect( () => {
    setModalData( costCenters );
  }, [ costCenters ] );

  const openModal = () => setIsOpen( true );
  const closeModal = () => setIsOpen( false );

  const getUserLink = useMemo(
    () =>
      role === 'customerUsers'
        ? id => `/customer-users/${id}`
        : id => `/accounts-joblink/${id}`,
    [ role ]
  );

  return (
    <ErrorBoundary>
      <div className='app-content'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <h1 className='title error-title'>{title}</h1>
            </div>
            <div className='col-12 col-md-6'>
              <div className='filter-group joblink-users-filter-group'>
                <div className='search'>
                  <SearchPanel
                    value={searchTerm}
                    onChange={setSearchTerm}
                  />
                </div>
              </div>
            </div>
            <div className='col-12 col-md-6 mt-4 mb-4 mb-md-0 mt-md-0'>
              <div className='d-flex justify-content-md-end'>
                <button
                  className='btn btn-md-block btn--green'
                  onClick={openModal}
                >
                  Send invite
                </button>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 mt-smd-0 mt-40'>
              <table className='table'>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    {role === 'customerUsers' && (
                      <>
                        <th>Phone</th>
                        <th>Class</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <div className='overlay'>
                      <Spinner />
                    </div>
                  ) : visibleItems.length ? (
                    visibleItems.map( user => (
                      <tr key={user.id}>
                        <td className='link-in'>
                          <Link to={user.id && getUserLink( user.id )}>
                            {user.firstName} {user.lastName}
                            {!user.id && (
                              <img
                                className='link-in__notification'
                                src={notificationIcon}
                              />
                            )}
                          </Link>
                        </td>
                        <td className='link-in'>
                          <Link to={user.id && getUserLink( user.id )}>{user.email}</Link>
                        </td>
                        {role === 'customerUsers' && (
                          <>
                            <td>
                              <Link to={user.id && getUserLink( user.id )}>
                                {user.phone}
                              </Link>
                            </td>
                            <td>
                              <Link to={user.id && getUserLink( user.id )}>
                                {user.class}
                              </Link>
                            </td>
                          </>
                        )}
                      </tr>
                    ) )
                  ) : (
                    <NoResultInTable colSpan={role === 'customerUsers' ? 4 : 2} />
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={role === 'customerUsers' ? 4 : 2}>
                      {PaginationComponent}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
      {role === 'customerUsers' ? (
        <InviteCustomerModal
          closeModal={closeModal}
          maxWidth={680}
          modalIsOpen={modalIsOpen}
        />
      ) : (
        <InviteModal
          role={role}
          data={modalData}
          maxWidth={680}
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
        />
      )}
    </ErrorBoundary>
  );
};

export default Accounts;
