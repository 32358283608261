import { DateTime } from 'luxon';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as CommentIcon } from '../../../../assets/images/operations/comment.svg';
import { isEventsIntersecting } from '../../../../utils/dates/dates';
import { concat } from '../../../../utils/utils';
import styles from '../../OperationsScreen.module.sass';
import { HoverBoxAbandoned } from './HoverBoxAbandoned';
import { HoverBoxComment } from './HoverBoxComment';
import { HoverBoxMultiple } from './HoverBoxMultiple';

export const OperationEventBadge = ( { events, employee, hasEventsInOtherJobRoles, filterLocationJobRole } ) => {
  const { eventsByDate } = useSelector( state => state.operationsPage );

  const [ hoverBoxTop, setHoverBoxTop ] = useState( false );
  const [ intersectingEvents, setIntersectingEvents ] = useState( [] );

  const dates = useMemo( () => {
    const firstEvent = events[0];
    if ( !firstEvent ) return null;
    return {
      start: DateTime.fromISO( firstEvent.start ).toFormat( 'HH:mm' ),
      end: DateTime.fromISO( firstEvent.end ).toFormat( 'HH:mm' )
    };
  }, [ { events } ] );

  const statusCount = useMemo( () => {
    return events.reduce( ( obj, e ) => {
      obj[e.status] = ( obj[e.status] || 0 ) + 1;
      return obj;
    }, {} );
  }, [ events ] );

  const status = useMemo( () => {
    const statusKeys = statusCount && Object?.keys( statusCount );
    const keyLength = statusKeys?.length;
  
    if ( keyLength > 1 || hasEventsInOtherJobRoles ) {
      return 'multiple';
    }
    if ( keyLength === 1 ) {
      const key = statusKeys.toString();
  
      if ( [ 'filled', 'completed', 'open' ].includes( key ) ) {
        const filledCount = statusCount?.filled;
        const completedCount = statusCount?.completed;
        const openCount = statusCount?.open;
  
        if ( ( key === 'filled' && filledCount === 1 ) || ( key === 'completed' && completedCount === 1 ) ) 
          return 'accepted';
        
        if ( key === 'open' && openCount === 1 ) return 'offered';

        if ( filledCount > 1 || completedCount > 1 || openCount > 1 ) return 'multiple';
      }
    }

    const event = events[0];
    const date = DateTime.fromISO( event?.start );
    const accepted =
      eventsByDate[date.toISODate()]?.filter(
        event => event?.JobOffer?.AcceptedEmployeeId === employee.id
      ) || [];
    
    const intersecting = accepted?.filter( acceptedEvent =>
      isEventsIntersecting( event, acceptedEvent )
    );

    if ( intersecting?.length ) {
      setIntersectingEvents( intersecting );
      return 'abandoned';
    }
    return 'abandoned';
  }, [ statusCount, filterLocationJobRole ] );

  const hasComment = useMemo( () => {
    if ( events.length > 1 ) return false;
    const event = events[0];
    if (
      ( event?.phase === 'order' || event?.phase === 'offer' ) &&
      ( event?.JobOrder?.description || event?.JobOrder?.internalComment )
    ) return true;
    return false;
  }, [ events ] );

  const multipleStatus = useMemo( () => {
    if ( status !== 'multiple' ) return;
    const acceptedCount = ( statusCount?.completed || 0 ) + ( statusCount?.filled || 0 ) ;

    // two or more events with acccepted status
    if ( acceptedCount > 1 ) return 'accepted-accepted';

    // some of the events are open and no accepted events in that job role / location, then jobs in other job roles are considered
    if ( statusCount?.open > 1 && acceptedCount === 0 ) {
      if ( hasEventsInOtherJobRoles?.status === 'accepted' )
        return 'offered-accepted';
      return 'offered-offered';
    }

    // if there is one accepted event and 0 to many open events in that job role / location, then jobs in other job roles are considered 
    if ( acceptedCount === 1 ) {
      if ( hasEventsInOtherJobRoles?.status === 'accepted' )
        return 'accepted-accepted';
      return 'accepted-offered';
    }

    // if there is only one open job in that job role / location, then box border depends on jobs in other job roles
    if ( statusCount?.open === 1 ) {
      return `offered-${hasEventsInOtherJobRoles?.status}`;
    }

    // if there are more than 1 sick-leave-completed events, then box border depends on jobs in other job roles
    if ( statusCount && statusCount[ 'completed-sick-leave' ] >= 1 ) {
      return 'abandoned-abandoned';
    }

    return 'offered-offered';

  }, [ statusCount, status ] );

  const className = useMemo(
    () => concat( styles['event'], styles[status], styles[multipleStatus] ),
    [ status, multipleStatus ]
  );

  const badgeRef = useCallback( element => {
    if ( element ) {
      setHoverBoxTop(
        ( window.scrollY + element.getBoundingClientRect().y ) /
          document.body.offsetHeight >
          0.9
      );
    }
  }, [] );

  const hoverBox = useMemo( () => {
    if ( !dates ) return;
    const event = events[0];
    let finalEvents = [ ...events ];
    finalEvents = finalEvents?.concat( hasEventsInOtherJobRoles?.jobEvents || [] );
    if ( status === 'multiple' ) {
      return (
        <HoverBoxMultiple
          date={dates?.start}
          employee={employee}
          events={finalEvents}
          className={hoverBoxTop ? styles['top'] : undefined}
        />
      );
    } else if ( intersectingEvents?.length ) {
      return (
        <HoverBoxAbandoned
          date={dates?.start}
          events={[ event, ...intersectingEvents ]}
          crossedOut={[ event?.id ]}
        />
      );
    } else {
      return (
        <HoverBoxComment
          date={dates?.start}
          employee={employee}
          event={event}
          className={hoverBoxTop ? styles['top'] : undefined}
        />
      );
    }
  }, [ status, dates, intersectingEvents, hasEventsInOtherJobRoles ] );

  return (
    <>
      <div
        className={dates && className}
        ref={badgeRef}
      >
        <span>{dates?.start}</span>
        <span>{dates?.end}</span>
        {hoverBox}
      </div>
      {hasComment && !intersectingEvents?.length && (
        <CommentIcon className={styles['comment-icon']} />
      )}
    </>
  );
};
