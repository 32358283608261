import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from '../../../../components/Spinner';
import {
  useGetJobEventQuery,
  useUpdateJobEventMutation,
  useUpdateJobOfferMutation,
  useUpdateJobOrderMutation
} from '../../../../store/apis/eventsApi';
import { useGetJobRoleQuery } from '../../../../store/apis/jobRolesApi';
import { useGetPresetTasksQuery } from '../../../../store/apis/presetTasksApi';
import {
  DescriptionField,
  InternalCommentField,
  JobRoleField,
  SkillsField,
  TaskField
} from '../../../NewOrderScreen/CreateOrderForm/components';
import { EndTimeField } from '../../../NewOrderScreen/CreateOrderForm/components/EndTimeField';
import { StartTimeField } from '../../../NewOrderScreen/CreateOrderForm/components/StartTimeField';
import './EditOrderForm.scss';

export const EditJobEventForm = ( { onClose } ) => {
  const { orderId } = useParams();
  const [ isLoading, setIsLoading ] = useState( false );

  const { data: event } = useGetJobEventQuery( orderId || skipToken );
  const { data: jobRole } = useGetJobRoleQuery( event ? event.JobRoleId : skipToken );
  const { data: presetTasks } = useGetPresetTasksQuery();

  const [ updateJobEvent ] = useUpdateJobEventMutation();
  const [ updateOrder ] = useUpdateJobOrderMutation();
  const [ updateOfferDescription ] = useUpdateJobOfferMutation();

  if ( !event || !jobRole || !presetTasks ) return <Spinner />;

  const initialValues = {
    jobRole: event.JobRole.id,
    tasks: event.Tasks,
    startTime: new Date( event.start ),
    endTime: new Date( event.end ),
    eventSkills: event.EventSkills.map( item => item.SkillId ),
    location: event.JobRole.Location.id,
    description:
      event.phase === 'order' ? event.JobOrder?.description : event.JobOffer?.description,
    internalComment: event.JobOrder.internalComment
  };

  const onSubmit = values => {
    // eslint-disable-next-line no-unused-vars
    setIsLoading( true );
    const { description, internalComment } = values;

    const updateEventFields = {
      start: values.startTime,
      end: values.endTime,
      TaskIds: typeof values.task !== 'number'
      ? values.task.map( el =>  parseInt( el ) )
      : [ parseInt( values.task ) ],
      EventSkills: values.eventSkills.map( id => ( { SkillId: id } ) )
    };

    const updatePromises = [];
    updatePromises.push(
      updateJobEvent( { fields: updateEventFields, eventId: orderId } ).unwrap()
    );

    if (
      description !== initialValues.description ||
      internalComment !== initialValues.internalComment
    ) {
      updatePromises.push(
        updateOrder( {
          description,
          internalComment,
          eventId: orderId
        } ).unwrap()
      );
      if ( event.phase === 'offer' ) {
        updatePromises.push( updateOfferDescription( { description, eventId: orderId } ).unwrap() );
        setIsLoading( false );
      }
    }

    Promise.all( updatePromises ).then( () => {
      onClose();
      setIsLoading( false );
    } );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <Form className='edit-order'>
        {isLoading && (
          <div className='overlay'>
            <Spinner />
          </div>
        )}
        <div className='d-flex align-items-baseline'>
          <StartTimeField eventTime={false}/>
          <EndTimeField
            eventDate={false}
            autoSelectDate={false}
          />
        </div>
        <div className='form-group'>
          <JobRoleField />
          <TaskField />
        </div>
        <div className='form-group'>
          <SkillsField />
        </div>
        <div className='form-group'>
          <DescriptionField />
        </div>
        <div className='form-group'>
          <InternalCommentField />
        </div>
        <div className='form-footer'>
          <div className='form-group align-items-center'>
            <button
              className='btn btn--green'
              type='submit'
              disabled={false}
            >
              Save
            </button>
            <button
              className='btn btn--default'
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </Form>
    </Formik>
  );
};
