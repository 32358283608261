import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { createContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ReactComponent as AlertIcon } from '../../../assets/images/alert-icon.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/chat-close.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/images/chat-settings.svg';
import { ReactComponent as SearchIcon } from '../../../assets/images/search-icon-green.svg';
import { Spinner } from '../../../components/Spinner';
import { useClickAwayListener, useMessagesListener } from '../../../hooks';
import {
  useDeleteConversationMutation,
  useDeleteMessageMutation,
  useEditMessageMutation,
  useGetSingleConversationsQuery,
  useReadMessageMutation,
  useUpdateConversationMutation
} from '../../../store/apis/chatApi';
import { InputSearchSmall } from '../../GlobalComponents/Elements';
import { ConfirmationModal } from '../ConfirmationModal';
import { EditMembersModal } from '../EditMembersModal';
import ChatBottom from './ChatBottom/ChatBottom';
import ChatMessage from './ChatMessage/ChatMessage';
import './chatScreen.sass';

export const ChatContext = createContext( {
  replyToMessage: undefined,
  discardReply: undefined,
  replyTo: undefined,
  messages: [],
  scrollToMessage: undefined
} );

const ChatScreen = () => {
  const { id } = useParams();
  const history = useHistory();

  useMessagesListener();

  const { data: conversation, isLoading, refetch } = useGetSingleConversationsQuery(
    id || skipToken
  );
  const fetchConversationData = async () => refetch();
  const [ updateConversation ] = useUpdateConversationMutation();
  const [ deleteConversation ] = useDeleteConversationMutation();
  const [ readMessage ] = useReadMessageMutation();
  const [ deleteMessage ] = useDeleteMessageMutation();
  const [ editMessage ] = useEditMessageMutation();

  const [ isEditMembersModalOpen, setEditMembersModalOpen ] = useState( false );
  const [ isDeleteModalOpen, setDeleteModalOpen ] = useState( false );
  const [ isSettingsOpen, setSettingsOpen ] = useState( false );

  const [ isSearchOpen, setSearchOpen ] = useState( false );
  const [ searchTerm, setSearchTerm ] = useState( '' );
  const [ Loading, setLoading ] = useState( false );

  const [ replyTo, setReplyTo ] = useState();
  const replyToMessage = message => setReplyTo( message );
  const discardReply = () => setReplyTo( undefined );

  const settingsRef = useRef( null );
  const chatRef = useRef( null );

  useEffect( () => {
    if ( chatRef.current ) {
      chatRef.current.scrollBy( {
        top: 10 ** 10
      } );
    }
  }, [ conversation ] );

  const toggleSettingsOpen = value => () => setSettingsOpen( value );
  useClickAwayListener( settingsRef, toggleSettingsOpen( false ) );

  const toggleUnidirectional = () => {
    updateConversation( { id, fields: { unidirectional: !conversation?.unidirectional } } );
  };

  const toggleEditMembersModalOpen = value => () => setEditMembersModalOpen( value );
  const toggleDeleteModalOpen = value => () => setDeleteModalOpen( value );

  const toggleSearch = value => () => setSearchOpen( value );
  const clearSearch = () => {
    setSearchOpen( false );
    setSearchTerm( '' );
  };

  const onDelete = () => {
    deleteConversation( id )
      .unwrap()
      .then( () => history.push( '/messages' ) );
  };

  const visibleMessages = useMemo( () => {
    if ( conversation ) {
      if ( isSearchOpen ) {
        return conversation.Messages.filter( message => {
          return message.text.toLowerCase().includes( searchTerm.toLowerCase() );
        } );
      } else {
        return conversation.Messages;
      }
    } else return [];
  }, [ searchTerm, isSearchOpen, conversation ] );

  const scrollToMessage = id => () => {
    if ( chatRef.current ) {
      const messageElement = chatRef.current.querySelector( `[data-id="${id}"]` );
      if ( messageElement ) {
        chatRef.current.scrollTo( {
          top: messageElement.offsetTop - chatRef.current.offsetTop,
          behavior: 'smooth'
        } );
      }
    }
  };

  const isUnread = useMemo( () => {
    if ( !conversation ) return false;
    const lastMessage = conversation.Messages[conversation.Messages.length - 1];
    if ( lastMessage ) {
      return lastMessage.id !== conversation.LastReadMessageId;
    }
    return false;
  }, [ conversation ] );

  useEffect( () => {
    if ( !conversation ) return;
    const lastMessage = conversation.Messages.length
      ? conversation.Messages[conversation.Messages.length - 1]
      : undefined;
    if ( lastMessage && isUnread ) {
      readMessage( { conversationId: conversation.id, messageId: lastMessage.id } );
    }
  }, [ conversation, isUnread ] );

  if ( isLoading ) return <Spinner />;

  const deleteSingleMessage = ( id ) => {
    setLoading( true );
    deleteMessage( { conversationId: conversation.id, messageId: id } )
      .unwrap()
      .then( () => {
        fetchConversationData();
        setLoading( false );
      } )
      .catch( () => setLoading( false ) );
  };

  const editSingleMessage = ( id, editText, editReplyToId ) => {
    setLoading( true );
    editMessage( { 
      conversationId: conversation.id,
      messageId: id, 
      message: { text:editText, ReplyToId:editReplyToId } 
    } )
      .unwrap()
      .then( () => setLoading( false ) )
      .catch( () => setLoading( false ) );
  };

  return (
    <ChatContext.Provider
      value={{
        replyToMessage,
        discardReply,
        replyTo,
        messages: conversation?.Messages,
        scrollToMessage
      }}
    >
      <div className='chat'>
        <div className='chat__top'>
          <h4 className='chat__subject'>{conversation?.topic}</h4>
          <div className='chat__actions'>
            {isSearchOpen ? (
              <InputSearchSmall
                onClose={clearSearch}
                value={searchTerm}
                onChange={setSearchTerm}
              />
            ) : (
              <SearchIcon onClick={toggleSearch( true )} />
            )}
            <div className='chat__settings'>
              <SettingsIcon onClick={toggleSettingsOpen( !isSettingsOpen )} />
              {isSettingsOpen && (
                <ul
                  ref={settingsRef}
                  className='drop-down'
                >
                  <li onClick={toggleEditMembersModalOpen( true )}>Edit participants</li>
                  <li onClick={toggleUnidirectional}>
                    {conversation?.unidirectional
                      ? 'Allow replies from everyone'
                      : 'Limit replies to staff only'}
                  </li>
                  <li>Move to Urgent</li>
                  <li onClick={toggleDeleteModalOpen( true )}>Delete conversation</li>
                </ul>
              )}
            </div>
            <Link to='/messages'>
              <CloseIcon />
            </Link>
          </div>

          {conversation?.unidirectional && (
            <div className='unidirectional'>
              <AlertIcon />
              Only Joblink staff can contribute to this conversation!
            </div>
          )}
        </div>
        {Loading && <span className='absolute vh-chat-center'>
          <Spinner />
        </span>}
        <div
          className='chat__middle'
          ref={chatRef}
        >
          {visibleMessages.map( message => {
            return (
              <ChatMessage
                deleteSingleMessage={deleteSingleMessage}
                editSingleMessage={editSingleMessage}
                key={message.id}
                message={message}
                conversation={conversation}
              />
            );
          } )}
        </div>

        <div className='chat__bottom'>
          <ChatBottom />
        </div>
        <EditMembersModal
          isOpen={isEditMembersModalOpen}
          close={toggleEditMembersModalOpen( false )}
          conversationId={id}
        />
        <ConfirmationModal
          close={toggleDeleteModalOpen( false )}
          confirm={{
            label: 'Yes, delete',
            onClick: onDelete
          }}
          isOpen={isDeleteModalOpen}
          text={
            <>
              Are you sure you want to delete the conversation entitled{' '}
              <b>{conversation?.topic}</b> for you and other{' '}
              {conversation?.Employees.length + conversation?.CustomerUsers.length}{' '}
              participants? This cannot be undone.
            </>
          }
        />
      </div>
    </ChatContext.Provider>
  );
};

export default ChatScreen;
