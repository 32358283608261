import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetMatchingEmployeesQuery } from '../../store/apis/employeesApi';

export const useGetMatchingEmployees = ( jobEvent, availability, costCenter, presetTask , page, perPage ) => {
  const { data: employees } = useGetMatchingEmployeesQuery(
    jobEvent
      ? {
          jobEvent: jobEvent,
          availability: availability,
          costCenterIds: costCenter === 'all' ? undefined : [ costCenter ],
          presetTaskIds: presetTask === 'all' ? undefined : presetTask,
          pagination: {
            offset: page * perPage,
            limit: perPage,
            includeTotal: true,
          },
        }
      : skipToken
  );

  return employees || [];
};
