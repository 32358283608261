import { skipToken } from '@reduxjs/toolkit/dist/query';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { ReactComponent as SvgFileIcon } from '../../../assets/images/csv-file.svg';
import { LabeledField } from '../../../components/LabeledField';
import { NoResults } from '../../../components/NoResults';
import { Spinner } from '../../../components/Spinner';
import { StyledSelect } from '../../../components/StyledSelect';
import { useGetJobEventsPolling } from '../../../hooks';
import {
  useGetCompaniesQuery,
  useGetCompanyQuery
} from '../../../store/apis/companiesApi';
import { eventLengthWithTimeBreak } from '../../../utils/dates';
import {
  combineSortingPredicates,
  eventsSortingPredicates
} from '../../../utils/sortings/eventsSortings';
import styles from '../ReportingScreen.module.sass';
import { downloadCsv } from './utils';

export const CompanyReporting = () => {
  const { data: companies } = useGetCompaniesQuery();
  const { data: events } = useGetJobEventsPolling();

  const [ companyId, setCompanyId ] = useState();
  const [ start, setStart ] = useState( DateTime.now().startOf( 'month' ) );
  const [ end, setEnd ] = useState( DateTime.now().endOf( 'month' ) );
  const [ order, setOrder ] = useState( 'date' );
  const [ locationsFilter, setLocationsFilter ] = useState( [] );

  const { data: company } = useGetCompanyQuery( companyId || skipToken );

  const locationsData = useMemo( () => {
    if ( !company ) return [];
    const locations = [];
    for ( const location of company.Locations ) {
      if ( locationsFilter.length && !locationsFilter.includes( location.id ) ) continue;
      const locationEvents = [];
      for ( const jobRole of location.JobRoles ) {
        const jobRoleEvents = events.filter(
          event =>
            event.phase === 'log' &&
            event.JobRoleId === jobRole.id &&
            DateTime.fromISO( event.start ) >= start &&
            DateTime.fromISO( event.end ) <= end
        );
        if ( jobRoleEvents.length ) {
          locationEvents.push( jobRoleEvents );
        }
      }
      if ( locationEvents.length ) {
        const sortedEvents = locationEvents
          .flat()
          .sort(
            order === 'date'
              ? combineSortingPredicates(
                  eventsSortingPredicates.byDate(),
                  eventsSortingPredicates.byJobRole()
                )
              : combineSortingPredicates(
                  eventsSortingPredicates.byJobRole(),
                  eventsSortingPredicates.byDate()
                )
          );
        let totalHours = 0;
        const jobRolesTotal = [];
        const cols = sortedEvents.map( event => {
          const start = DateTime.fromISO( event.start );
          const end = DateTime.fromISO( event.end );
          const length = eventLengthWithTimeBreak( event );
          totalHours += length;
          const jobRoleTotal = jobRolesTotal.find(
            item => item.jobRole.id === event.JobRoleId
          );
          if ( jobRoleTotal ) jobRoleTotal.hours += length;
          else jobRolesTotal.push( { jobRole: event.JobRole, hours: length } );
          return [
            {
              value: order === 'date' ? start.toFormat( 'd.LL.' ) : event.JobRole.name
            },
            {
              value: order === 'jobRole' ? start.toFormat( 'd.LL.' ) : event.JobRole.name
            },
            { value: length, className: styles['center'] },
            { value: start.toFormat( 'HH:mm' ), className: styles['center'] },
            {
              value: end.toFormat(
                end.hasSame( start, 'day' )
                  ? 'HH:mm'
                  : `HH:mm (+${Math.ceil( end.diff( start, 'day' ).days )})`
              ),
              className: styles['center']
            },
            {
              value: `${event.JobOffer.AcceptedEmployee?.firstName} ${event.JobOffer.AcceptedEmployee?.lastName}`
            }
          ];
        } );
        locations.push( {
          location,
          total: totalHours,
          events: cols,
          jobRolesTotal
        } );
      }
    }
    return locations;
  }, [ company, start, end, order, locationsFilter ] );

  const onDateChange = setter => date => setter( DateTime.fromJSDate( date ) );

  const grandTotal = useMemo(
    () => locationsData.reduce( ( total, locationData ) => total + locationData.total, 0 ),
    [ locationsData ]
  );

  const downloadLocationCsv = locationData => () => {
    downloadCsv(
      [
        [
          order === 'date' ? 'Date' : 'Job Role',
          order === 'jobRole' ? 'Date' : 'Job Role',
          'Total hours',
          'Start Time',
          'End Time'
        ],
        ...locationData.events.map( e => e.map( col => col.value ) ),
        [ 'Total', '', locationData.total ]
      ],
      `${locationData.location.name}-${DateTime.now().toFormat( 'D' )}`
    );
  };

  if ( !companies || !events ) return <Spinner />;

  return (
    <>
      <div className={styles['filters']}>
        <LabeledField label={'Company'}>
          <StyledSelect
            options={companies.map( company => ( {
              value: company.id,
              label: company.name
            } ) )}
            value={companyId}
            onChange={setCompanyId}
          />
        </LabeledField>
        <LabeledField label='Start'>
          <ReactDatePicker
            selected={start.toJSDate()}
            onChange={onDateChange( setStart )}
            className='form-control'
            wrapperClassName={styles['date-input']}
            dateFormat='dd/MM/yyyy'
          />
        </LabeledField>
        <LabeledField label='End'>
          <ReactDatePicker
            selected={end.toJSDate()}
            onChange={onDateChange( setEnd )}
            className='form-control'
            wrapperClassName={styles['date-input']}
            dateFormat='dd/MM/yyyy'
          />
        </LabeledField>
        <LabeledField label={'Listing order'}>
          <StyledSelect
            options={[
              { label: 'List by dates', value: 'date' },
              { label: 'List by job roles', value: 'jobRole' }
            ]}
            value={order}
            onChange={setOrder}
          />
        </LabeledField>

        {company && company.Locations.length > 1 && (
          <LabeledField label={'Locations'}>
            <StyledSelect
              options={company.Locations.map( location => ( {
                value: location.id,
                label: location.name
              } ) )}
              value={locationsFilter}
              onChange={setLocationsFilter}
              isMulti
              containerStyle={{ maxWidth: 'none' }}
            />
          </LabeledField>
        )}
      </div>
      {!locationsData?.length ? (
        <NoResults />
      ) : (
        <>
          {locationsData.map( ( locationData, locationIndex ) => (
            <section
              key={locationIndex}
              className={styles['reporting-item']}
            >
              <h1>
                {locationData.location.name}{' '}
                <SvgFileIcon
                  onClick={downloadLocationCsv( locationData )}
                  className={styles['download']}
                />
              </h1>

              <table className='table'>
                <thead>
                  <tr>
                    <th>{order === 'date' ? 'Date' : 'Job Role'}</th>
                    <th>{order === 'jobRole' ? 'Date' : 'Job Role'}</th>
                    <th className={styles['center']}>Total hours</th>
                    <th className={styles['center']}>Start Time</th>
                    <th className={styles['center']}>End Time</th>
                    <th>Employee</th>
                  </tr>
                </thead>
                <tbody>
                  {locationData.events.map( ( eventData, index ) => (
                    <tr key={index}>
                      {eventData.map( ( col, colIndex ) => (
                        <td
                          key={colIndex}
                          className={col.className}
                        >
                          {col.value}
                        </td>
                      ) )}
                    </tr>
                  ) )}
                  <tr>
                    <td>
                      <b>Total:</b>
                    </td>
                    <td></td>
                    <td className={styles['center']}>
                      <b>{locationData.total}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
          ) )}
          <div className={styles['grand-total']}>
            <h4>Grand total:</h4>
            <span>{grandTotal}</span>
          </div>
          <div className={styles['job-roles-total']}>
            <h4>Job roles total:</h4>
            <ul>
              {locationsData
                .flatMap( locationData => locationData.jobRolesTotal )
                .map( jobRoleTotal => (
                  <li key={jobRoleTotal.jobRole.id}>
                    <span>{jobRoleTotal.jobRole.name}</span>
                    <span>{jobRoleTotal.hours}</span>
                  </li>
                ) )}
            </ul>
          </div>
        </>
      )}
    </>
  );
};
