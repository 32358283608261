import styles from '../../OperationsScreen.module.sass';
import { BadgeHoverBox } from './BadgeHoverBox';
import { BadgeHoverBoxEvent } from './BadgeHoverBoxEvent';

export const HoverBoxMultiple = ( { employee, date, events, className } ) => {
  return (
    <BadgeHoverBox
      date={date}
      employee={employee}
      className={className}
    >
      {events.map( event => (
        <BadgeHoverBoxEvent
          event={event}
          employee={employee}
          key={event.id}
        >
          <p className={styles['comment']}>{event?.JobOffer?.description}</p>
          <p className={styles['comment']}>{event?.JobOrder?.internalComment}</p>
        </BadgeHoverBoxEvent>
      ) )}
    </BadgeHoverBox>
  );
};
