import enGb from 'date-fns/locale/en-GB';
import { useFormikContext } from 'formik';
import ReactDatePicker from 'react-datepicker';
import './DateTimeField.scss';

export const TimeField = ( { label, name, filterTime, className, setCount } ) => {
  const { values, setFieldValue } = useFormikContext();

  const value = values[name] ? 
    new Date( Math.round( new Date( values[name] ).getTime() / ( 5 * 60 * 1000 ) ) * ( 5 * 60 * 1000 ) ) 
    : undefined;

  const onChange = date => {
    setFieldValue( name, date );
    setCount && setCount( changeCount => changeCount + 1 );
  };

  return (
    <div className={[ 'form-row w-100 date-time-field', className ].join( ' ' )}>
      <label>{label}</label>
      <div className='d-flex'>
        <ReactDatePicker
          className='form-control'
          selected={value}
          showTimeSelect
          showTimeSelectOnly
          dateFormat={'HH:mm'}
          onChange={onChange}
          timeFormat={'HH:mm'}
          filterTime={filterTime}
          timeIntervals={5}
          wrapperClassName={'time'}
          locale={enGb}
        />
      </div>
    </div>
  );
};
