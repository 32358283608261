import { DateTime } from 'luxon';
import * as yup from 'yup';

export const createOrderFormSchema = yup.object( {
  task: yup.mixed().required(),
  startTime: yup.date().required(),
  endTime: yup.date().when( 'startTime', ( startTime, schema ) => {
    return (
      startTime &&
      schema.min( DateTime.fromJSDate( startTime ).plus( { minutes: 1 } ).toJSDate() )
    );
  } ),
  eventSkills: yup.array().required(),
  description: yup.string()
} );
