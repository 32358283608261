import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ScreenContainer } from '../../components/ScreenContainer';
import { useGetEmployeesByAvailability, useGetMatchingEmployees } from '../../hooks';
import { useDeclineAcceptedOfferMutation, useGetJobEventQuery, useSendOfferMutation } from '../../store/apis/eventsApi';
import { Filters, Header, Table } from './components';

export const EmployeeAssignmentScreen = () => {
  const { orderId } = useParams();
  const history = useHistory();

  const { data: jobEvent } = useGetJobEventQuery( orderId );
  const [ sendOffer ] = useSendOfferMutation();

  const presetTask = jobEvent?.Task?.PresetTaskId ? [ jobEvent.Task.PresetTaskId ] : 'all';

  const [ costCenter, setCostCenter ] = useState( 'all' );
  const [ availability, setAvailability ] = useState( 'certainlyAvailable' );
  const [ searchTerm, setSearchTerm ] = useState( '' );
  const [ checkedEmployees, setCheckedEmployees ] = useState( [] );
  const [ employeeId, setEmployeeId ] = useState( [] );
  const [ page, setPage ] = useState( 0 );
  const [ perPage, setPerPage ] = useState( 25 );
  const { data: employees, pagination } = useGetMatchingEmployees( jobEvent, availability, costCenter, presetTask, page, perPage );
  const employeesByAvailabiliy = useGetEmployeesByAvailability( jobEvent );
  const [ declineAcceptedOfferMutation ] = useDeclineAcceptedOfferMutation();

  const isEmployeeAvailable = employee => {
    const isAvailable = !!employeesByAvailabiliy.available.find(
      availableEmployee => availableEmployee.id === employee.id
    );
    if ( isAvailable ) return 'available';

    const isUnavailable = !!employeesByAvailabiliy.unavailable.find(
      unavailableEmployee => unavailableEmployee.id === employee.id
    );
    if ( isUnavailable ) return 'unavailable';

    return 'unknown';
  };

  const [ selectedEmployees, setSelectedEmployees ] = useState( [] );

  const getEmployeesIds = ( uncheckedEmployee ) => {
    if ( employeeId.includes( uncheckedEmployee ) ) {
      setEmployeeId( ( prevIds ) => prevIds.filter( ( id ) => id !== uncheckedEmployee ) );
    } else {
      setEmployeeId( [ ...employeeId, uncheckedEmployee ] );
    }
  };

  const saveChanges = selectedEmployees?.length > 0 || employeeId?.length > 0;

  const onAssign = async () => {
    if ( employeeId.length > 0 ) {
      try {
        await Promise.all(
          employeeId.map( ( employee ) =>
            declineAcceptedOfferMutation( {
              id: orderId,
              employeeId: employee
            } )
          )
        );
        if ( !selectedEmployees.length ) {
          history.goBack();
        }
      }
      catch ( error ) {
        console.log( error );
      }
    }

    if ( selectedEmployees.length ) {
      sendOffer( {
        jobEventId: orderId,
        employeesIds: selectedEmployees
      } );
      history.goBack();
    }
  };

  useEffect( () => {
    if ( searchTerm.length >= 3 ) {
      setCostCenter( 'all' );
      setAvailability( 'all' );
    }
  }, [ searchTerm ] );

  useEffect( () => {
    const initialCheckedState = jobEvent?.JobOffer?.ReceivedEmployees.reduce( ( acc, employee ) => {
      return { ...acc, [ employee.id ]: true };
    }, {} );
    setCheckedEmployees( initialCheckedState );
  }, [ jobEvent ] );

  const filteredEmployees = useMemo( () => {
    if ( searchTerm.length >= 3 )
      return employees.filter( employee =>
        [ employee.firstName, employee.lastName ]
          .join( ' ' )
          .toLowerCase()
          .includes( searchTerm.toLowerCase() )
      );
    return employees;
  }, [ employees, searchTerm ] );

  if ( !jobEvent ) return null;
  if ( !jobEvent.JobOffer ) return null;

  const onPageChange = ( { selected } ) => {
    setPage( selected );
  };

  return (
    <ScreenContainer>
      <div className='container-fluid'>
        <Header
          jobEvent={jobEvent}
          onAssign={onAssign}
          selectedEmployees={selectedEmployees}
          saveChanges={saveChanges}
          unAssignEmployee={employeeId}
        />
        <Filters
          costCenter={costCenter}
          setCostCenter={setCostCenter}
          availability={availability}
          setAvailability={setAvailability}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
        <Table
          employees={filteredEmployees}
          onPageChange={onPageChange}
          setPerPage={setPerPage}
          pagination={pagination}
          perPage={perPage}
          jobEvent={jobEvent}
          isEmployeeAvailable={isEmployeeAvailable}
          selectedEmployees={selectedEmployees}
          setSelectedEmployees={setSelectedEmployees}
          checkedEmployees={checkedEmployees}
          setCheckedEmployees={setCheckedEmployees}
          getEmployeesIds= {getEmployeesIds}
          availability={availability}
        />
      </div>
    </ScreenContainer>
  );
};
