import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { EmployeeRow } from './EmployeeRow';
import { JobRoleRow } from './JobRoleRow';

export const JobRoleGroup = ( { jobRole, location, jobRoleGroups, employees } ) => {
  const { weeksCount, employeeFilter, locationFilter, allEvents, costCenter } = useSelector(
    state => state.operationsPage
  );

  const jobRoleEvents = useMemo( () => {

    return allEvents.filter( event => {
      return event.JobRoleId === jobRole.id;
    } );
  }, [ allEvents, jobRole ] );

  const eventsEmployees = useMemo( () => {
    if ( !allEvents ) return [];
    let filteredEvents = jobRoleEvents.filter(
      event =>
        event.JobOffer?.AcceptedEmployeeId || event.JobOffer?.ReceivedEmployees?.length
    );
    if ( employeeFilter ) {
      filteredEvents = filteredEvents.filter( event => {
        const acceptedEmployeeId = event.JobOffer?.AcceptedEmployeeId;
        if ( acceptedEmployeeId === employeeFilter.id ) return true;
        const receivedEmployees = event.JobOffer?.ReceivedEmployees;
        return !!receivedEmployees?.find( employee => employee.id === employeeFilter.id );
      } );
    }
    let eventsEmployees = {};
    const addEmployee = employee => { eventsEmployees[ employee.id ] = employee; };
    for ( const event of filteredEvents ) {
      if ( event.JobOffer.AcceptedEmployee  ) addEmployee( event.JobOffer.AcceptedEmployee );

      else
        for ( const employee of event?.JobOffer?.ReceivedEmployees ) {
          addEmployee( employee );
        }
    }
    return Object.values( eventsEmployees ).sort( ( a, b ) => {
      if ( a.lastName > b.lastName ) return 1;
      if ( a.lastName < b.lastName ) return -1;
      return a.firstName > b.firstName ? 1 : -1;
    } );
  }, [ allEvents, jobRole, employeeFilter, weeksCount ] );

  const selectedEmployees = useMemo( () => {
    if ( costCenter !== 'all' && !employeeFilter ) return employees;
    if ( !employeeFilter ) return eventsEmployees;
    const filterEventsEmployees = eventsEmployees.filter( employee => {
      return employee?.id === employeeFilter?.id;
    } );
    return filterEventsEmployees;
  }, [ eventsEmployees, employeeFilter, costCenter, employees ] );

  if (
    ( !eventsEmployees.length || ( locationFilter && locationFilter.id !== location.id ) ) &&
    !jobRoleEvents.length
  )
    return null;

  return (
    <>
      {( locationFilter && locationFilter.id === location.id || !locationFilter ) ? (
        <JobRoleRow
          jobRole={jobRole}
          location={location}
        /> )
      : null}
      {selectedEmployees.map( ( employee, index ) => (
        <EmployeeRow
          key={index}
          jobRoleId={jobRole.id}
          filterLocationJobRole={true}
          locationId={location.id}
          employee={employee}
          jobRoleGroups={jobRoleGroups}
        />
      ) )}
    </>
  );
};
