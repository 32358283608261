import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export const searchByName = ( items, term ) => {
  if ( !term || term.trim() === '' ) {
    return items;
  }

  const sanitizedTerm = term.toLowerCase().trim();

  const filteredItems = items.reduce( ( filteredItems, item  ) => {
    const itemName = item.name.toLowerCase();
    const matchingLocations = item.Locations.filter( location => {
      return location.name.toLowerCase().includes( sanitizedTerm );
    } );

    if ( itemName.includes( sanitizedTerm ) || matchingLocations.length > 0 ) {
      const newItem = itemName.includes( sanitizedTerm )
        ? { ...item }
        : { ...item, Locations: matchingLocations };
      if ( !filteredItems.some( filteredItem => filteredItem.id === newItem.id ) ) {
        filteredItems.push( newItem );
      }
    }

    return filteredItems;
  }, [] );

  return filteredItems;
};

export const searchByFirstAndLastName = ( items, term ) => {
  if ( term === 0 ) {
    return items;
  }
  return items?.filter( item => {
    return (
      `${item.firstName} ${item.lastName}`.toLowerCase().indexOf( term.toLowerCase() ) > -1
    );
  } );
};

export const searchByJobRole = ( items, term ) => {
  return () => {
    if ( term === 0 ) {
      return items;
    }
    return items.filter( item => {
      return item.JobRole.name.toLowerCase().indexOf( term.toLowerCase() ) > -1;
    } );
  };
};

export const exportToCSV = ( csvData, fileName ) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const ws = XLSX.utils.json_to_sheet( csvData );
  const wb = { Sheets: { data: ws }, SheetNames: [ 'data' ] };
  const excelBuffer = XLSX.write( wb, { bookType: 'xlsx', type: 'array' } );
  const data = new Blob( [ excelBuffer ], { type: fileType } );
  FileSaver.saveAs( data, fileName + fileExtension );
};
