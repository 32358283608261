import { useMemo } from 'react';
import styles from '../../OperationsScreen.module.sass';
import { BadgeHoverBox } from './BadgeHoverBox';
import { BadgeHoverBoxEvent } from './BadgeHoverBoxEvent';

export const HoverBoxComment = ( { employee, date, event, className } ) => {
  const comment = useMemo( () => {
    if ( event.phase === 'order' ) {
      return {
        description: event.JobOrder?.description,
        internalComment: event.JobOrder?.internalComment
      };
    }
    if ( event.phase === 'offer' ) {
      return {
        description: event.JobOffer?.description,
        internalComment: event.JobOrder?.internalComment
      };
    }
  }, [ event ] );

  const isRejected = useMemo(
    () =>
      event.JobOffer.ReceivedEmployees.find( item => item.id === employee.id )
        ?.SentEmployees.type === 'rejected',
    [ event, employee ]
  );

  return (
    <BadgeHoverBox
      date={date}
      employee={employee}
      className={[ className, isRejected ? styles['rejected'] : undefined ].join( ' ' )}
    >
      <BadgeHoverBoxEvent
        event={event}
        isLink={false}
      >
        <p className={styles['comment']}>{comment?.description}</p>
        <p className={styles['comment']}>{comment?.internalComment}</p>
      </BadgeHoverBoxEvent>
    </BadgeHoverBox>
  );
};
