import { DateTime } from 'luxon';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ReactSelect from 'react-select';
import { ReactComponent as DeleteIcon } from '../../assets/images/delete-icon.svg';
import { ReactComponent as EditIcon } from '../../assets/images/edit-icon.svg';
import ErrorBoundary from '../../components/ErrorBoundary';
import { InfoRows } from '../../components/InfoRows';
import LinkBack from '../../components/LinkBack';
import { Skills } from '../../components/Skills';
import { Spinner } from '../../components/Spinner/Spinner';
import { Tasks } from '../../components/Tasks';
import { useGetCostCentersQuery } from '../../store/apis/costCentersApi';
import { useGetProfileQuery } from '../../store/apis/currentUserApi';
import {
  useGetAllEmployeesQuery,
  useGetEmployeeLeavesQuery,
  useGetEmployeeQuery,
  useUpdateEmployeeMutation
} from '../../store/apis/employeesApi';
import { useResetPasswordEmployeeMutation } from '../../store/apis/resetPasswordApi';
import { selectTheme } from '../../theme';
import { CreateLeaveModal } from './CreateLeaveModal/CreateLeaveModal';
import DeletePastLeaveModal from './DeletePastLeaveModal';
import EditPastLeaveModal from './EditPastLeaveModal';
import { ChangeStatusModal } from './EditPersonalInfoScreen/ChangeStatusModal';
import { EmployeeCertificates } from './EmployeeCertificates';

const ProfileScreen = () => {
  // Other Hooks
  const history = useHistory();
  const { employeeId } = useParams();
  const location = useLocation();
  const previousPath = location.state?.from || '/';

  const employees = useGetAllEmployeesQuery( {
    filters: { statuses: [ 'active', 'locked', 'blocked' ] },
    includes: {
      Skills: true,
      Certificates: true,
    },
  } );
  const costCenters = useGetCostCentersQuery();
  const profile = useGetProfileQuery();
  const leaves = useGetEmployeeLeavesQuery( employeeId );

  const [ updateEmployee ] = useUpdateEmployeeMutation();
  const { data: employeeFetched } = useGetEmployeeQuery(
      {
        id: employeeId,
        params: { Tasks: true }
      }
  );

  const employee = useMemo( () => {
    if ( !employees.data ) return;
    return employees.data.find( employee => employee.id === +employeeId );
  }, [ employeeId, employees.data ] );

  const costCenter = useMemo( () => {
    if ( !costCenters.data || !employee ) return;
    return costCenters.data.find( center => center.id === employee.CostCenterId );
  }, [ costCenters.data, employee ] );

  const currentLeaves = useMemo( () => {
    if ( !leaves.data ) return [];
    return leaves.data.filter( leave => new Date( leave.end ) > new Date() );
  }, [ leaves.data ] );

  const pastLeaves = useMemo( () => {
    if ( !leaves.data ) return [];
    return leaves.data.filter( leave => new Date( leave.end ) < new Date() );
  }, [ leaves.data ] );

  // States
  const [ editModalIsOpen, setEditModalIsOpen ] = useState( false );
  const [ deleteModalIsOpen, setDeleteModalIsOpen ] = useState( false );
  const [ createLeaveModalOpen, setCreateLeaveModalOpen ] = useState( false );
  const [ editInternalComment, setEditInternalComment ] = useState( false );
  const [ internalComment, setInternalComment ] = useState( '' );
  const [ accountStatus, setAccountStatus ] = useState();
  const [ targetAccountStatus, setTargetAccountStatus ] = useState();
  const [ isAccountStatusModalOpen, setAccountStatusModalOpen ] = useState( false );
  const [ leaveToEdit, setLeaveToEdit ] = useState();
  const [ leaveToDelete, setLeaveToDelete ] = useState();
  const [ loading, setLoading ] = useState( false );

  useEffect( () => {
    if ( employee ) {
      setAccountStatus( employee.status );
    }
  }, [ employee ] );

  // Actions
  const closeModal = callback => callback( false );
  const saveInternalComment = () => {
    updateEmployee( { employeeId: employee.id, fields: { internalComment } } );
    setEditInternalComment( false );
  };
  useEffect( () => setInternalComment( employee?.internalComment ), [ employee ] );
  const toggleAccountStatusModal = open => () => setAccountStatusModalOpen( open );

  const [ resetPassword, { isSuccess, isError } ] = useResetPasswordEmployeeMutation();

  const onResetPasswordClick = () => {
    if ( employee && employee.email ) {
      resetPassword( employee.email );
    }
  };

  const onAccountStatusChange = option => {
    if ( employee && option.value === employee.status ) return;
    if ( option.value === 'active' ) {
      updateEmployee( {
        employeeId,
        fields: { status: option.value },
      } ).then( () => {
        setAccountStatus( option.value );
      } );
    } else {
      setTargetAccountStatus( option.value );
      setAccountStatusModalOpen( true );
    }
  };

  const goEdit = () => history.push( `${history.location.pathname}/edit-person-info` );

  const toggleEditComment = value => () => setEditInternalComment( value );

  const openCreteLeaveModal = () => setCreateLeaveModalOpen( true );

  useMemo( () => {
    if ( previousPath === '/operations' ) {
      openCreteLeaveModal();
    } 
    }, [ previousPath ] );

  const editLeave = leave => () => {
    setLeaveToEdit( leave );
    setCreateLeaveModalOpen( true );
  };

  const deleteLeave = leave => () => {
    setLeaveToDelete( leave?.id );
    setDeleteModalIsOpen( true );
  };

  return (
    <ErrorBoundary>
      <div className='app-content'>
        <div className='container-fluid'>
          <div className='row mb-3'>
            <div className='col-12'>
              <LinkBack title='Back to Employees' />
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-md-6'>
              <h1 className='title d-block error-title'>
                {employee?.firstName} {employee?.lastName}
              </h1>
            </div>
            <div className='col-12 col-md-6'>
              <div className='d-flex justify-content-end align-items-end'>
                {profile.data?.type === 'JoblinkUser' && (
                  <div className='d-flex flex-column mr-3'>
                    <span>Status</span>
                    <ReactSelect
                      value={
                        accountStatus
                          ? { value: accountStatus, label: accountStatus }
                          : undefined
                      }
                      options={[
                        { value: 'active', label: 'Active' },
                        { value: 'locked', label: 'Locked' },
                        { value: 'blocked', label: 'Blocked' },
                      ]}
                      theme={selectTheme}
                      styles={{
                        container: base => ( { ...base, width: 150 } ),
                        singleValue: base => ( {
                          ...base,
                          textTransform: 'capitalize',
                        } ),
                      }}
                      onChange={onAccountStatusChange}
                    />
                  </div>
                )}
                <button
                  className='btn btn--green disabled'
                  disabled
                  onClick={() => history.push( '/messages' )}
                >
                  Send Message
                </button>
              </div>
            </div>
          </div>
          <InfoRows
            onlyContainerBox
            rows={[
              {
                items: [
                  { label: 'Email', value: employee?.email },
                  { label: 'Phone', value: employee?.phone },
                  {
                    label: 'Password',
                    element: (
                      <div className='info-col__title'>
                        {( isSuccess || isError ) && (
                          <span className={isSuccess ? 'text-success' : 'text-danger'}>
                            {isSuccess
                              ? 'Reset link has been sent'
                              : 'Failed to send reset link'}
                          </span>
                        )}
                        <button
                          className='btn btn--light-green'
                          onClick={onResetPasswordClick}
                        >
                          Send password reset link
                        </button>
                      </div>
                    ),
                  },
                ],
                props: { className: 'mb-4' },
              },
              {
                items: [
                  { label: 'Cost center', value: costCenter?.name },
                  {
                    label: 'Home address',
                    value: ( employee?.address_Street && employee?.address_ZipCode && employee?.address_City )
                          ? `${employee.address_Street}, ${employee.address_ZipCode}, ${employee.address_City}`
                          : '',
                  },
                  { label: 'Bank account', value: employee?.bankAccount },
                ],
                props: { className: 'mb-4' },
              },
              {
                items: [
                  { label: 'Clothing size', value: employee?.clothingSize },
                  { label: 'ID Number', value: employee?.idNumber },
                  {
                    element: employee && employee.photoUrl.length > 0 && (
                      <div className='big-profile-photo'>
                        <img
                          src={employee?.photoUrl}
                          alt=''
                        />
                      </div>
                    ),
                  },
                ],
                props: { className: 'mb-1' },
              },
            ]}
          >
            <button
              className='btn btn--light-green'
              onClick={goEdit}
            >
              Edit personal info
            </button>
          </InfoRows>
          <InfoRows
            rows={[
              {
                items: [
                  {
                    label: 'Internal Comment',
                    element: (
                      <textarea
                        className='editable-textarea form-control'
                        value={internalComment}
                        onChange={e => setInternalComment( e.target.value )}
                        disabled={!editInternalComment}
                        autoFocus
                        onFocus={function( e ) {
                          var val = e.target.value;
                          e.target.value = '';
                          e.target.value = val;
                        }}
                      />
                    ),
                    children: (
                      <div className='mt-3'>
                        {editInternalComment ? (
                          <>
                            <button
                              className='btn pl-3 pr-3 mr-3 btn--light-green'
                              onClick={saveInternalComment}
                            >
                              Save
                            </button>
                            <button
                              className='btn pl-3 pr-3 cancel'
                              onClick={toggleEditComment( false )}
                            >
                              Cancel
                            </button>
                          </>
                        ) : (
                          <button
                            className='btn pl-3 pr-3 btn--light-green'
                            onClick={() => setEditInternalComment( true )}
                          >
                            Edit
                          </button>
                        )}
                      </div>
                    ),
                  },
                ],
              },
              {
                items: [
                  {
                    label: 'Favorites',
                    element: (
                      <Skills
                        skills={employee?.RatedByJobRoles}
                        className='mt-1'
                      />
                    ),
                  },
                  {
                    label: 'Disfavorites',
                    element: (
                      <Skills
                        skills={employee?.RatedJobRoles}
                        className='mt-1'
                      />
                    ),
                  },
                ],
              },
              {
                items: [
                  {
                    element: (
                      <>
                        {loading && <span className='absolute vh-center'>
                          <Spinner />
                        </span>}
                        <div className='d-flex justify-content-md-between'>
                          <div>
                            <div>
                              <span className='info-col__label'>Current leave</span>
                              <div className='info-col__title'>
                                {currentLeaves.length
                                  ? currentLeaves.map( ( leave, index ) => (
                                      <div className='d-flex align-items-center' key={index}>
                                        {formatLeaveText( leave )}
                                        <span
                                          className='cursor-pointer ml-3'
                                          onClick={editLeave( currentLeaves[index] )}
                                        >
                                          <EditIcon />
                                        </span>
                                        <span
                                          className='cursor-pointer ml-3'
                                          onClick={deleteLeave( currentLeaves[index] )}
                                        >
                                          <DeleteIcon />
                                        </span>
                                      </div>
                                    ) )
                                  : '-'}
                              </div>
                            </div>
                            {pastLeaves.length ? (
                              <div className='mt-4'>
                                <span className='info-col__label'>Past leaves</span>
                                <div className='info-col__title'>
                                  {pastLeaves.map( ( leave, index ) => (
                                    <div className='d-flex align-items-center' key={index}>
                                      {formatLeaveText( leave )}
                                      <span
                                        className='cursor-pointer ml-3'
                                        onClick={editLeave( pastLeaves[index] )}
                                      >
                                        <EditIcon />
                                      </span>
                                      <span 
                                        className='cursor-pointer ml-3'
                                        onClick={deleteLeave( pastLeaves[index] )}
                                      >
                                        <DeleteIcon />
                                      </span>
                                    </div>
                                  ) )}
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div>
                            <button
                              className='btn btn--light-green ml-3'
                              onClick={openCreteLeaveModal}
                            >
                              Add new
                            </button>
                          </div>
                        </div>
                      </>
                    ),
                  },
                ],
              },
              {
                items: [
                  {
                    label: 'Task',
                    element: (
                      <Tasks
                        tasks={employeeFetched}
                        className='mt-2'
                      />
                    ),
                  },
                ],
              },
              {
                items: [
                  {
                    label: 'Skills',
                    element: (
                      <Skills
                        skills={employee?.Skills}
                        className='mt-2'
                      />
                    ),
                  },
                  {
                    label: 'Certificates',
                    element: <EmployeeCertificates employee={employee} />,
                  },
                ],
              },
            ]}
          />
        </div>
      </div>
      <EditPastLeaveModal
        maxWidth={900}
        modalIsOpen={editModalIsOpen}
        closeModal={() => closeModal( setEditModalIsOpen )}
        response={null}
      />
      <DeletePastLeaveModal
        maxWidth={680}
        modalIsOpen={deleteModalIsOpen}
        closeModal={() => closeModal( setDeleteModalIsOpen )}
        response={null}
        leaveToDelete={leaveToDelete}
        setLoading={setLoading}
      />
      <ChangeStatusModal
        isOpen={isAccountStatusModalOpen}
        targetStatus={targetAccountStatus}
        maxWidth={680}
        closeModal={toggleAccountStatusModal( false )}
        onConfirm={setAccountStatus}
      />
      <CreateLeaveModal
        isOpen={createLeaveModalOpen}
        setOpen={setCreateLeaveModalOpen}
        leaveToEdit={leaveToEdit}
      />
    </ErrorBoundary>
  );
};

export default memo( ProfileScreen );

export const leaveTypes = {
  sick: 'Sairausloma',
  parental: 'Vanhempainvapaa',
  ['sick-pending']: 'Sairausloma (vahvistamatta)',
  holiday: 'Loma',
  ['annual-holiday']: 'Vuosiloma',
  ['annual-leave']: 'Vuosivapaa',
  other: 'Muu poissaolo',
};

function formatLeaveText( leave ) {
  return (
    <>
      <span>{`${leaveTypes[leave.type]}: ${DateTime.fromISO( leave.start ).toFormat(
        'd.L.yyyy'
      )} - ${DateTime.fromISO( leave.end ).toFormat( 'd.L.yyyy' )}`}</span>
    </>
  );
}
