import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useFormikContext } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { useGetJobRoleQuery } from '../../../../store/apis/jobRolesApi';
import { useGetPresetTasksQuery } from '../../../../store/apis/presetTasksApi';
import { selectTheme } from '../../../../theme';
import { all } from '../../../../utils/utils';

export const TaskField = () => {
  const { values, setFieldValue } = useFormikContext();
  const [ value, setValue ] = useState( null );

  const jobRoleId = +values.jobRole;

  const { data: jobRole } = useGetJobRoleQuery( jobRoleId || skipToken );
  const { data: presetTasks } = useGetPresetTasksQuery();

  const options = useMemo( () => {
    if ( !jobRole || !presetTasks ) return [];
    if ( jobRoleId !== jobRole.id ) return [];
    return jobRole.Tasks.map( task => ( {
      label: presetTasks.find( presetTask => presetTask.id === task.PresetTaskId ).name,
      value: task.id
    } ) );
  }, [ jobRole, presetTasks, jobRoleId ] );

  useEffect( () => {
    if ( jobRoleId !== jobRole?.id ) {
      setFieldValue( 'task', [] );
    };
  }, [ jobRoleId, jobRole ] );

  useEffect( () => {
    if ( options?.length ) {
      if ( values.tasks?.length ) {
        const taskIds = values.tasks.map( task => task.id );
        let val = taskIds.map( taskId => options?.find( option => option.value === taskId ) );
        if ( !val || !all( val ) ) {
          val = [ options[0] ];
        }
        setFieldValue( 'task', val.map( v => v.value ) );
        setValue( val );
      } else {
        setFieldValue( 'task', [ options[0].value ] );
        setValue( [ options[0] ] );
      }
    }
  }, [ options ] );

  const onSelectChange = objs => {
    setValue( objs );
    setFieldValue( 'task', objs.map( obj => obj.value ) );
  };

  return (
    <div className='form-row w-100 select-box'>
      <label>Task</label>
      <Select
        options={options}
        value={value}
        isMulti
        onChange={onSelectChange}
        theme={selectTheme}
        isDisabled={!options?.length}
        styles={{
          control: base => ( { ...base, height: '100%' } ),
          container: base => ( {
            ...base,
            width: '100%',
            minWidth: '200px',
          } )
        }}
        />
    </div>
  );
};
