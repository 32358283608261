import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { calcWeekEventsLengthSum } from '../../../../utils/dates';
import { concat } from '../../../../utils/utils';
import styles from '../../OperationsScreen.module.sass';
import { EventsCountBadge } from '../badges';

export const OpenEventsRow = ( { jobEvents, weeksCount, weekStart } ) => {
  const { allEvents, costCenter } = useSelector( state => state.operationsPage );

  const openEvents = useMemo(
    () =>
      jobEvents.filter( jobEvent => {
        let isStatusCorrect = [ 'open', 'unprocessed' ].includes( jobEvent.status );

        if ( costCenter !== 'all' && isStatusCorrect ) {
          isStatusCorrect = jobEvent?.JobRole?.Location.CostCenterId === costCenter;
        }

        return isStatusCorrect;
      } ),
    [ jobEvents, costCenter ]
  );

  const totalPerformedTime = useMemo( () => {
    const completedEvents = allEvents.filter( event => event.phase === 'log' );
    return Array( weeksCount )
      .fill()
      .map( ( _, weekIndex ) =>
        calcWeekEventsLengthSum(
          completedEvents,
          weekStart.weekNumber + weekIndex
        ).toFormat( 'hh:mm' )
      );
  }, [ allEvents ] );

  const tiles = useMemo( () => {
    return [ ...Array( weeksCount ).keys() ].flatMap( weekIndex =>
      [ ...Array( 7 ).keys() ]
        .map( index => {
          const day = weekStart.plus( { days: index, weeks: weekIndex } );
          const dayEvents = openEvents.filter( jobEvent =>
            DateTime.fromISO( jobEvent.start ).hasSame( day, 'day' )
          );
          return {
            content: (
              <EventsCountBadge
                date={day}
                events={dayEvents}
                key={index}
                showLocationJobRoleHeading={true}
              />
            )
          };
        } )
        .concat( {
          content: totalPerformedTime[weekIndex],
          style: styles['tile-total']
        } )
    );
  }, [ openEvents ] );

  return (
    <>
      <div className={concat( styles['tile'], styles['tile-big'], styles['location'] )}>
        Open
      </div>
      {tiles.map( ( tile, index ) => (
        <div
          className={concat( styles['tile'], styles['location'], tile.style )}
          key={index}
        >
          {tile.content}
        </div>
      ) )}
    </>
  );
};
