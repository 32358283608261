import enGb from 'date-fns/locale/en-GB';
import { useFormikContext } from 'formik';
import ReactDatePicker from 'react-datepicker';
import './DateTimeField.scss';

export const DateTimeField = ( { label, name, filterDate } ) => {
  const { values, setFieldValue } = useFormikContext();

  const value = values[name] ? new Date( values[name] ) : undefined;

  const onChange = date => {
    setFieldValue( name, date );
  };

  return (
    <div className={'form-row w-100 date-time-field mr-0 pl-0 pr-0 date-time-field'}>
      <label>{label}</label>
      <div className='d-flex'>
        <ReactDatePicker
          className='form-control'
          selected={value}
          onChange={onChange}
          filterDate={filterDate}
          dateFormat={'dd.MM.yyyy'}
          locale={enGb}
        />
      </div>
    </div>
  );
};
