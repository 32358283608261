import { DateTime } from 'luxon';

export const groupJobEventsByJobRole = jobEvents => {
  const groups = [];

  for ( const jobEvent of jobEvents ) {
    if ( [ 'canceled', 'failed' ].includes( jobEvent.status ) ) continue;
    const jobRole = jobEvent.JobRole;
    const existingGroup = groups.find( group => group.jobRole.id === jobRole.id );
    if ( existingGroup ) {
      existingGroup.jobEvents.push( jobEvent );
    } else {
      groups.push( {
        jobRole,
        jobEvents: [ jobEvent ],
        byEmployee: undefined
      } );
    }
  }

  for ( const group of groups ) {
    group.byEmployee = groupJobEventsByEmployee( group.jobEvents );
  }

  return groups.sort( ( a, b ) => {
    if ( a.jobRole.Location.name === b.jobRole.Location.name )
      return a.jobRole.name.localeCompare( b.jobRole.name );
    else
      return a.jobRole.Location.name.localeCompare( b.jobRole.Location.name );
  } );
};

export const groupJobEventsByEmployee = jobEvents => {
  const groups = [];

  for ( const jobEvent of jobEvents ) {
    const acceptedEmployee = jobEvent.JobOffer?.AcceptedEmployee;
    const receivedEmployees = jobEvent.JobOffer?.ReceivedEmployees;

    if ( acceptedEmployee ) {
      const existingGroup = groups.find(
        group => group.employee.id === acceptedEmployee.id
      );
      if ( existingGroup ) {
        existingGroup.jobEvents.accepted.push( jobEvent );
      } else {
        groups.push( {
          employee: acceptedEmployee,
          jobEvents: {
            accepted: [ jobEvent ],
            received: [],
            byDate: undefined
          }
        } );
      }
    }

    if ( receivedEmployees?.length ) {
      for ( const employee of receivedEmployees ) {
        const existingGroup = groups.find( group => group.employee.id === employee.id );
        const receivedEmployeeOffer = jobEvent.JobOffer?.ReceivedEmployees?.find( e => e.id === employee.id );
        if ( receivedEmployeeOffer?.SentEmployees?.type === 'rejected' ) continue;
        if ( jobEvent.status === 'filled' && jobEvent.JobOffer?.AcceptedEmployeeId !== employee.id ) continue;
        if ( existingGroup ) {
          existingGroup.jobEvents.received.push( jobEvent );
        } else {
          groups.push( {
            employee,
            jobEvents: {
              accepted: [],
              received: [ jobEvent ],
              byDate: undefined
            }
          } );
        }
      }
    }
  }

  for ( const group of groups ) {
    group.jobEvents.byDate = {
      accepted: groupJobEventsByStartDate( group.jobEvents.accepted ),
      received: groupJobEventsByStartDate( group.jobEvents.received )
    };
  }

  return groups;
};

export const groupJobEventsByStartDate = jobEvents => {
  const tmp = {};
  for ( const event of jobEvents ) {
    const isoDate = DateTime.fromISO( event.start ).toISODate();
    if ( !tmp[isoDate] ) tmp[isoDate] = [];
    if ( !tmp[isoDate].find( item => item.id === event.id ) ) {
      tmp[isoDate].push( event );
    }
  }
  return tmp;
};
