import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { fieldErrorClass } from '../../../../constants';
import { useInviteEmployeeMutation } from '../../../../store/apis/invitationApi';
import { selectTheme } from '../../../../theme';
import validators from '../../../../utils/validators';
import { TasksField } from './TasksField';

const initialValues = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  idNumber: '',
  address_Street: '',
  address_ZipCode: '',
  address_City: '',
  bankAccount: '',
  clothingSize: 'XS',
};

const clothingSizes = [ 'XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL' ];

const InviteForm = props => {
  const { data, closeModal } = props;
  const [ costCentersOptions, setCostCentersOptions ] = useState( null );
  const [ selectedCostCenter, setSelectedCostCenter ] = useState( {
    value: 'all',
    label: 'All Cost centers',
  } );

  const [ inviteEmployee, { isSuccess, isError } ] = useInviteEmployeeMutation();

  useEffect( () => {
    setCostCentersOptions( data.map( el => ( { value: el?.id, label: el?.name } ) ) );
    setCostCentersOptions( prev => [ { value: 'all', label: 'All Cost centers' }, ...prev ] );
  }, [ data ] );

  const handleCostCenterNameChange = selectedOption =>
    setSelectedCostCenter( selectedOption );

  const onFormSubmit = ( values, { setSubmitting } ) => {
    const data = { ...values, CostCenterId: selectedCostCenter.value };
    inviteEmployee( data ).finally( () => {
      setSubmitting( false );
    } );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onFormSubmit}
      validateOnMount
    >
      {( { handleChange, errors, touched, handleSubmit, isSubmitting, isValid } ) => (
        <Form
          onSubmit={handleSubmit}
          className='form'
        >
          <div className='form-group'>
            <div className='form-row'>
              <label htmlFor='firstName'>First name*</label>
              <Field
                className={`form-control ${
                  errors.firstName && touched.firstName && fieldErrorClass
                }`}
                type='text'
                id='firstName'
                name='firstName'
                validate={validators.required}
                onChange={handleChange}
              />
            </div>
            <div className='form-row'>
              <label htmlFor='lastName'>Last name*</label>
              <Field
                className={`form-control ${
                  errors.lastName && touched.lastName && fieldErrorClass
                }`}
                type='text'
                id='lastName'
                name='lastName'
                validate={validators.required}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className='form-group'>
            <div className='form-row'>
              <label htmlFor='phone'>Phone</label>
              <Field
                className={`form-control ${
                  errors.phone && touched.phone && fieldErrorClass
                }`}
                type='phone'
                id='phone'
                name='phone'
                onChange={handleChange}
              />
            </div>
            <div className='form-row'>
              <label htmlFor='email'>Email*</label>
              <Field
                className={`form-control ${
                  errors.email && touched.email && fieldErrorClass
                }`}
                type='email'
                id='email'
                name='email'
                validate={validators.required}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className='form-group'>
            <div className='form-row'>
              <label htmlFor='idNumber'>ID Number</label>
              <Field
                className={`form-control ${
                  errors.idNumber && touched.idNumber && fieldErrorClass
                }`}
                id='idNumber'
                name='idNumber'
                onChange={handleChange}
              />
            </div>
            <div className='form-row'>
              <label htmlFor='bankAccount'>Bank Account</label>
              <Field
                className={`form-control ${
                  errors.bankAccount && touched.bankAccount && fieldErrorClass
                }`}
                id='bankAccount'
                name='bankAccount'
                onChange={handleChange}
              />
            </div>
          </div>
          <div className='form-group'>
            <div className='form-row'>
              <label htmlFor='address_City'>City</label>
              <Field
                className={`form-control ${
                  errors.address_City && touched.address_City && fieldErrorClass
                }`}
                id='address_City'
                name='address_City'
                onChange={handleChange}
              />
            </div>
            <div className='form-row'>
              <label htmlFor='address_Street'>Street</label>
              <Field
                className={`form-control ${
                  errors.address_Street && touched.address_Street && fieldErrorClass
                }`}
                id='address_Street'
                name='address_Street'
                onChange={handleChange}
              />
            </div>
          </div>
          <div className='form-group'>
            <div className='form-row'>
              <label htmlFor='address_ZipCode'>ZIP</label>
              <Field
                className={`form-control ${
                  errors.address_ZipCode && touched.address_ZipCode && fieldErrorClass
                }`}
                id='address_ZipCode'
                name='address_ZipCode'
                onChange={handleChange}
              />
            </div>
            <div className='form-row'>
              <label htmlFor='clothingSize'>Clothing Size</label>
              <Field
                as='select'
                id='clothingSize'
                name='clothingSize'
                className='form-control'
              >
                {clothingSizes.map( size => (
                  <option
                    key={size}
                    value={size}
                    label={size}
                  />
                ) )}
              </Field>
            </div>
          </div>
          <div>
            <div className='select-box w-100 mb-3'>
              <label>
                <span>Cost center name*</span>
                <Select
                  value={selectedCostCenter}
                  onChange={handleCostCenterNameChange}
                  options={costCentersOptions}
                  theme={selectTheme}
                />
              </label>
            </div>
          </div>
          <div>
            <div className='select-box w-100 mb-3'>
              <label>
                <span>Task</span>
                <TasksField />
              </label>
            </div>
          </div>
          <div className='form-footer'>
            <div className='form-row mt-4 mb-2'>
              {isSuccess && (
                <span
                  className='text-danger'
                  style={{ fontSize: 12 }}
                >
                  Invite sent successfully
                </span>
              )}
              {isError && (
                <span
                  className='text-danger'
                  style={{ fontSize: 12 }}
                >
                  Failed to send invite
                </span>
              )}
              <button
                className='btn btn--green'
                type='submit'
                disabled={
                  isSubmitting ||
                  !isValid ||
                  isSuccess ||
                  isError ||
                  selectedCostCenter.value === 'all'
                }
              >
                Send
              </button>
            </div>
            <div className='form-row mt-2 mb-0'>
              <button
                className='btn btn--default'
                onClick={() => closeModal()}
                type='button'
              >
                Cancel
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default InviteForm;
