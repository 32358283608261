import { useFormikContext } from 'formik';
import { StyledSelect } from '../../../../components/StyledSelect';
import { useGetAllEmployeesQuery } from '../../../../store/apis/employeesApi';

export const EmployeesField = () => {

  const { values, setFieldValue } = useFormikContext();
  
  const employeeId = +values.employeeId;
  
  const { data: employees } = useGetAllEmployeesQuery();
  
  const onChange = id => {
    setFieldValue( 'employeeId', id );
  };

  return (
    <div className='form-row w-100 select-box'>
      <label>Employee</label>
      <StyledSelect
        options={employees?.map( employee => ( {
          label: employee.firstName + ' ' + employee.lastName,
          value: employee.id
        } ) )}
        onChange={onChange}
        value={employeeId}
        containerStyle={{
          maxWidth: 'none',
          marginLeft: 0
        }}
        isDisabled={!employees?.length}
      />
    </div>
  );
};
