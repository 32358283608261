export const Tasks = ( { tasks, className } ) => {
  if ( !tasks ) return null;

  return (
    <div className={[ 'required-skills', className ].join( ' ' )}>
      {tasks?.PresetTasks.map( task => (
        <div
          className='required-skills'
          key={task.id}
        >
          <div className='skill-box pr-2'>{task.name}</div>
        </div>
      ) )}
    </div>
  );
};
