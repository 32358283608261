import { DateTime } from 'luxon';
import { useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as AddIcon } from '../../../../assets/images/operations/add-icon-light.svg';
import { concat } from '../../../../utils/utils';
import styles from '../../OperationsScreen.module.sass';
import { HoverBoxJobRole } from './HoverBoxJobRole';

export const EventsCountBadge = ( { events = [], date, jobRole, location, showLocationJobRoleHeading } ) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const [ hoverBoxTop, setHoverBoxTop ] = useState( false );

  const goCreateOrder = () => {
    history.push( '/new-order', {
      costCenterId: location?.CostCenterId,
      locationId: location?.id,
      jobRoleId: jobRole?.id,
      companyId: location?.CompanyAccount.name,
      date: date
        .set( {
          hour: DateTime.now().hour,
        } )
        .toJSDate(),
    } );
  };

  const goPastCreateOrder = () => {
    history.push( '/new-order', {
      costCenterId: location?.CostCenterId,
      locationId: location?.id,
      jobRoleId: jobRole?.id,
      companyId: location?.CompanyAccount.name,
      from: pathname,
      date: date
        .set( {
          hour: DateTime.now().hour,
        } )
        .toJSDate(),
    } );
  };

  const badgeRef = useCallback( element => {
    if ( element ) {
      setHoverBoxTop(
        ( window.scrollY + element.getBoundingClientRect().y ) /
          document.body.offsetHeight >
          0.9
      );
    }
  }, [] );

  const isUnprocessed = useMemo( () => {
    return !!events?.find( jobEvent => jobEvent.status === 'unprocessed' );
  }, [ events ] );

  const areAllEmployeesInSomeEventRejected = useMemo( () => {
    return events.some( event => event?.JobOffer?.ReceivedEmployees?.every( emp => emp.SentEmployees?.type === 'rejected' ) );
  }, [ events ] );

  if ( !events?.length )
    return (
      <>
        {date.startOf( 'day' ) < DateTime.now().startOf( 'day' ) ?
            <AddIcon
              className={styles['badge-add']}
              onClick={goPastCreateOrder}
            />
          :
          <AddIcon
            className={styles['badge-add']}
            onClick={goCreateOrder}
          />
        }
      </>
    );

  return (
    <div
      className={concat(
        styles['badge-count'],
        areAllEmployeesInSomeEventRejected || isUnprocessed ? styles['unprocessed'] : undefined
      )}
      ref={badgeRef}
    >
      <span>{events.length}</span>
      <HoverBoxJobRole
        date={date}
        events={events}
        jobRole={jobRole}
        location={location}
        className={hoverBoxTop ? styles['top'] : undefined}
        showLocationJobRoleHeading={showLocationJobRoleHeading}
      />
    </div>
  );
};
