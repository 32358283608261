import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useMemo } from 'react';
import { ReactComponent as CheckIcon } from '../../../assets/images/check.svg';
import { useGetEmployeeQuery } from '../../../store/apis/employeesApi';
import { calcEventsLengthSum } from '../../../utils/dates';
import styles from '../EmployeeAssignmentScreen.module.scss';
import { EmployeeAcceptedJobs } from './EmployeeAcceptedJobs';
import { EmployeeRowName } from './EmployeeRowName';
import { SkillBox } from './SkillBox';
import { RatingIcon } from './icons';
import { DateTime } from 'luxon';

export const EmployeeRow = ( {
  employee,
  isSelected,
  onChange,
  availability,
  requiredSkills,
  jobEvent,
  disabled,
  availabilityType
} ) => {
  const { data: employeeFetched } = useGetEmployeeQuery(
    disabled
      ? {
          id: employee.id,
          params: {
            AcceptedOffers: true,
            start: DateTime.fromISO( jobEvent.start ).minus( { days: 180 } ).toJSDate(),
            end: DateTime.fromISO( jobEvent.end ).plus( { hours: 12.1 } ).toJSDate()
          }
        }
      : skipToken
  );

  const extendedEmployee = disabled ? employeeFetched : employee;

  const employeeSkillIds = extendedEmployee?.Skills?.map( skill => skill.id ) || [];

  const employeeHasSkill = skill => employeeSkillIds.includes( skill.id );

  const acceptedOffers = extendedEmployee?.AcceptedOffers || [];
  const executedOffer = acceptedOffers.filter(
    offer => offer.JobEvent.status === 'completed' && offer?.JobEvent?.JobRoleId === jobEvent?.JobRoleId
  );
  const executedOffers = acceptedOffers.filter(
    offer => offer.JobEvent.status === 'completed'
  );
  const ratedByEmployee = extendedEmployee?.RatedJobRoles?.[0]?.LikedByEmployees?.type;
  const ratedByJobRole = extendedEmployee?.RatedByJobRoles?.[0]?.LikeEmployees?.type;

  const name = `${extendedEmployee?.firstName} ${extendedEmployee?.lastName}`;
  const halfYearExperience = useMemo( () => {
    const totalHours = calcEventsLengthSum( executedOffer?.map( offer => offer.JobEvent ) ).as( 'hours' );
    const roundedHours = Math.round( totalHours );
    return roundedHours;
  }, [ executedOffers ] );

  const allHalfYearExperience = useMemo( () => {
    const totalHours = calcEventsLengthSum( executedOffers?.map( offer => offer.JobEvent ) ).as( 'hours' );
    const roundedHours = Math.round( totalHours );
    return roundedHours;
  }, [ executedOffers ] );

  const skillsContent = useMemo(
    () =>
      requiredSkills.map( skill => {
        return (
          <SkillBox
            skill={skill}
            success={employeeHasSkill( skill ) ? true : false}
            key={skill.id}
          />
        );
      } ),
    [ requiredSkills, employeeHasSkill ]
  );
  const employeeRatingContent = useMemo(
    () => (
      <div className={styles['rating']}>
        <span>
          <RatingIcon rating={ratedByEmployee} />
        </span>
        <span>
          <RatingIcon rating={ratedByJobRole} />
        </span>
      </div>
    ),
    [ ratedByEmployee, ratedByJobRole ]
  );

  return (
    <tr key={extendedEmployee?.id}>
      <td
        className={styles['checkbox-container']}
        onClick={onChange}
      >
        <div
          className={styles['checkbox']}
          data-checked={isSelected}
        >
          <CheckIcon style={{ opacity: isSelected ? 1 : 0 }} />
        </div>
      </td>
      <td
        align='center'
        title={name}
        style={{ position: 'relative' }}
      >
        <EmployeeRowName
          availability={availability}
          employee={employee}
          name={name}
          availabilityType={availabilityType}
        />
      </td>
      <td className='table__th-center'>
        <p>{halfYearExperience} h / {allHalfYearExperience} h</p>
      </td>
      <td>
        <EmployeeAcceptedJobs
          acceptedOffers={acceptedOffers}
          jobEvent={jobEvent}
        />
      </td>
      <td className='table-skills'>{skillsContent}</td>
      <td className='table__th-center'>{employeeRatingContent}</td>
    </tr>
  );
};
