import React, { useState } from 'react';
import LinkBack from '../../../components/LinkBack';
import { Spinner } from '../../../components/Spinner';

export const Header = ( { jobEvent, selectedEmployees, onAssign, unAssignEmployee,saveChanges } ) => {
  const [ loading, setLoading ] = useState( false );

  const handleAssign = async () => {
    setLoading( true );

    try {
      await onAssign();

      setLoading( false );
    } catch ( error ) {
      setLoading( false );
    }
  };

  return (
    <div className='row mb-4'>
      <div className='col-12 col-md-12'>
        <LinkBack title='Back to Event listing' />
        <div className='title-box mt-3'>
          <h1 className='title mr-2'>
            Employee assignment (
            {jobEvent?.JobOffer?.ReceivedEmployees.length + selectedEmployees.length - unAssignEmployee?.length})
          </h1>
          <button
          className={saveChanges ? 'btn btn--green ml-4' : 'btn btn--light-green ml-4'}
            onClick={handleAssign}
          >
            Save changes
          </button>
        </div>
        <h2 className='mt-0'>
          {jobEvent.JobRole.Location.name}
          <br />
          {jobEvent.JobRole.name}
          <br />
          {new Date( jobEvent?.start ).toLocaleDateString( 'de-DE', { day: 'numeric', month: 'numeric' } )}{' '}
          {new Date( jobEvent?.start ).toLocaleTimeString( 'de-DE', { hour: '2-digit', minute: '2-digit' } )} -{' '}
          {new Date( jobEvent?.end ).toLocaleTimeString( 'de-DE', { hour: '2-digit', minute: '2-digit' } )}
        </h2>
      </div>
      {loading && (
        <div className='overlay'>
          <Spinner />
        </div>
      )}
    </div>
  );
};
