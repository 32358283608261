import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useGetEmployeeAvailabilitiesQuery, useGetEmployeeLeavesQuery } from '../../../../store/apis/employeesApi';
import { calcWeekEventsLengthSum } from '../../../../utils/dates/dates';
import { concat, getEmployeeWeekTiles } from '../../../../utils/utils';
import styles from '../../OperationsScreen.module.sass';
import { EmployeeHoverBox } from '../EmployeeHoverBox';

export const EmployeeRow = ( {
  employee,
  jobRoleId,
  className,
  jobRoleGroups,
  showEmpty,
  filterLocationJobRole,
  locationId
} ) => {
  const { currentWeekStart, weeksCount, locationFilter, allEvents } = useSelector(
    state => state.operationsPage
  );

  const leaves = useGetEmployeeLeavesQuery( employee?.id );

  const { data: availabilities } = useGetEmployeeAvailabilitiesQuery( {
    id: employee.id,
    range: {
      start: currentWeekStart.toJSDate().toISOString(),
      end: currentWeekStart
        .plus( { week: weeksCount - 1 } )
        .endOf( 'week' )
        .toJSDate()
        .toISOString()
    }
  } );

  const employeeEventsByDate = useMemo( () => {
    if ( !allEvents ) return {};
    const filteredEvents = allEvents.filter( event => {
      if ( event.status === 'failed' ) return false;
      if ( event.status === 'canceled' ) return false;
      if ( event?.JobOffer?.ReceivedEmployees?.filter( e => {
        if ( e.id === employee.id && e.SentEmployees?.type !== 'rejected'  ) return true;
      }
      )?.length === 0 ) return false;
      if ( locationFilter && event.JobRole.Location.id !== locationFilter.id ) return false;
      if ( jobRoleId && event.JobRoleId !== jobRoleId ) return false;
      if ( locationId && event.JobRole.Location.id !== locationId ) return false;
      if (
        event.JobOffer?.AcceptedEmployee === null &&
        event.JobOffer?.ReceivedEmployees.length === 0
      )
        return false;
      const acceptedEmployeeId = event.JobOffer?.AcceptedEmployeeId;
      if ( acceptedEmployeeId && acceptedEmployeeId !== employee.id ) return false;
      const receivedEmployees = event.JobOffer?.ReceivedEmployees;
      if ( !receivedEmployees?.find( item => item.id === employee.id ) ) return false;
      return true;
    } );
    const byDate = {};
    filteredEvents.forEach( event => {
      const isoDate = DateTime.fromISO( event.start ).toISODate();
      if ( byDate[isoDate] ) {
        byDate[isoDate] = [ ...byDate[isoDate], event ];
      } else {
        byDate[isoDate] = [ event ];
      }
    } );
    return byDate;
  }, [ allEvents, currentWeekStart, jobRoleId, locationFilter, employee ] );

  const availabilitiesByDate = useMemo( () => {
    if ( !availabilities ) return {};
    const byDate = {};
    for ( const availability of availabilities ) {
      let current = DateTime.fromISO( availability.start );
      const end = DateTime.fromISO( availability.end );
      while ( current <= end ) {
        byDate[current.toISODate()] = availability;
        current = current.plus( { day: 1 } );
      }
    }
    return byDate;
  }, [ availabilities ] );

  const unAvailabiltyByDate = useMemo( () => {
    if ( !leaves.currentData ) return {};
    const byDate = {};
    for ( const leave of leaves.currentData ) {
      let current = DateTime.fromISO( leave.start );
      const end = DateTime.fromISO( leave.end );
      while ( current<= end ) {
        byDate[current.toISODate()] = leave;
        current = current.plus( { day: 1 } );
      }
    }
    return byDate;
  }, [ leaves ] );

  const eventsLength = useMemo( () => {
    const completedEvents = allEvents.filter( event => {
      if ( jobRoleId && event.JobRoleId !== jobRoleId ) return false;
      return event.JobOffer?.AcceptedEmployeeId === employee.id;
    } );

    return Array( weeksCount )
      .fill()
      .map( ( _, weekIndex ) =>
        calcWeekEventsLengthSum(
          completedEvents,
          currentWeekStart.weekNumber + weekIndex
        ).toFormat( 'hh:mm' )
      );
  }, [ weeksCount, employeeEventsByDate, currentWeekStart ] );

  const tiles = useMemo( () => {
    return Array( weeksCount )
      .fill()
      .map( ( _, weekIndex ) => [
        ...getEmployeeWeekTiles(
          currentWeekStart.plus( { week: weekIndex } ),
          employeeEventsByDate,
          availabilitiesByDate,
          unAvailabiltyByDate,
          employee,
          jobRoleId,
          jobRoleGroups,
          filterLocationJobRole
        ),
        {
          className: styles['tile-total'],
          content: eventsLength[weekIndex]
        }
      ] );
  }, [ employeeEventsByDate, availabilitiesByDate, unAvailabiltyByDate, currentWeekStart, weeksCount ] );

  const shouldShow = useMemo( () => {
    const hasEvents = !!Object.values( employeeEventsByDate )
      .flat().length;
    return hasEvents || !!showEmpty;
  }, [ employeeEventsByDate, employee, showEmpty ] );

  if ( !shouldShow ) return null;

  return (
    <>
      <div
        className={concat(
          styles['tile'],
          styles['tile-big'],
          styles['employee'],
          className
        )}
      >
        <Link to={`/accounts-employees/${employee.id}`}>{employee.lastName}, {employee.firstName}</Link>
        <EmployeeHoverBox
          employee={employee}
          className={{
            container: styles['hover-box'],
            header: styles['header'],
            hoverEvent: styles['hover-event']
          }}
          jobRoleId={jobRoleId || 'all'}
        />
      </div>
      {tiles.map( week =>
        week.map( ( day, index ) => (
          <div
            className={concat( styles['tile'], className, day?.className )}
            key={index}
          >
            {day?.content}
          </div>
        ) )
      )}
    </>
  );
};
