import { useEffect, useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import ErrorBoundary from '../../components/ErrorBoundary';
import { EventItemHoverBoxContext } from '../../components/EventItemHoverBox/EventItemHoverBoxContext';
import LinkBack from '../../components/LinkBack';
import { useGetJobEventsPolling } from '../../hooks';
import { usePagination } from '../../hooks/usePagination';
import { useSubmitJobLogMutation } from '../../store/apis/eventsApi';
import { eventListingActions } from '../../store/slices/eventListingFiltersSlice';
import { selectStyles, selectTheme } from '../../theme';
import { jobLogFilterValuesMap } from '../../utils/filters/eventsFilters';
import { eventsSortingsMap } from '../../utils/sortings/eventsSortings';
import { getJobLogData } from '../../utils/utils';
import { LogEventItem } from './LogEventItem';
import { LogItemHoverBox } from './LogItemHoverBox';
import { logFilterOptions, theadData } from './data';

const JobLogsProcessingScreen = ( { title } ) => {
  const [ selectedEvents, setSelectedEvents ] = useState( [] );
  const [ selectAll, setSelectAll ] = useState( false );
  const [ hoveredEvent, setHoveredEvent ] = useState( null );
  const [ hoveredElementBoundingBox, setHoveredElementBoundingBox ] = useState( null );

  const {
    processingSortBy,
    processingSortOrder,
    processingStartDate,
    processingEndDate,
    statusFilterJobProcessing
  } = useSelector( state => state.filters );
  const dispatch = useDispatch();

  const events = useGetJobEventsPolling();
  const [ submitJobLog ] = useSubmitJobLogMutation();

  const logEvents = useMemo( () => {
    if ( !events.data ) return [];
    return events.data.filter(
      event =>
        event.phase === 'log' &&
        event.status !== 'canceled' &&
        event.JobLog.phase !== 'finalized'
    );
  }, [ events.data ] );

  const filteredLogEvents = useMemo( () => {
    let result = logEvents;
    if ( statusFilterJobProcessing.value !== 'all' ) {
      result = jobLogFilterValuesMap[statusFilterJobProcessing.value]( logEvents );
    }
    if ( processingStartDate )
      result = result.filter( event => new Date( event.start ) > processingStartDate );
    if ( processingEndDate )
      result = result.filter( event => new Date( event.end ) < processingEndDate );
    return result;
  }, [
    logEvents,
    statusFilterJobProcessing.value,
    processingStartDate,
    processingEndDate
  ] );

  const eventsCompareValue = useMemo(
    () => JSON.stringify( filteredLogEvents.map( event => event.id ) ),
    [ filteredLogEvents ]
  );

  const sortedLogEvents = useMemo( () => {
    if ( processingSortBy ) {
      return eventsSortingsMap[processingSortBy]( filteredLogEvents, processingSortOrder );
    }
    return filteredLogEvents;
  }, [ filteredLogEvents, processingSortBy, processingSortOrder ] );

  useEffect( () => setSelectedEvents( [] ), [ eventsCompareValue ] );

  const { visibleItems, PaginationComponent } = usePagination( sortedLogEvents );

  const onSelect = selected => e => {
    setSelectedEvents(
      e.target.checked
        ? selectedEvents.concat( selected )
        : selectedEvents.filter( event => event.id !== selected.id )
    );
    setSelectAll( false );
  };

  const onSelectAll = e => {
    setSelectAll( e.target.checked );
    setSelectedEvents( e.target.checked ? filteredLogEvents : [] );
  };

  useEffect( () => {
    setSelectAll( false );
    setSelectedEvents( [] );
  }, [ visibleItems ] );

  const toggleSorting = shouldSortBy => () => {
    const sortByChanged =
      processingSortBy === shouldSortBy
        ? processingSortOrder === 'asc'
          ? 'desc'
          : 'asc'
        : 'asc';
    dispatch( eventListingActions.setProcessingSortOrder( sortByChanged ) );
    dispatch( eventListingActions.setProcessingSortBy( shouldSortBy ) );
  };

  const handleStatusFilterChange = selectedOption =>
    dispatch( eventListingActions.setStatusFilterJobProcessing( selectedOption ) );

  const onAcceptSelected = () => {
    selectedEvents.forEach( event => {
      submitJobLog( {
        id: event.id,
        jobLog: getJobLogData( event ),
        decision: 'accept',
        comment: ''
      } );
    } );
  };

  const onDateChange = action => date => dispatch( action( date ) );

  return (
    <ErrorBoundary>
      <div className='app-content'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <LinkBack title='Takaisin' />
              <h1 className='title mt-3'>
                {title} ({filteredLogEvents.length})
              </h1>
            </div>
            <div className='col-12 mb-4'>
              <div className='filters'>
                <div className='filters__body align-items-end flex-wrap'>
                  <button
                    className='btn btn--green btn--small mr-3'
                    onClick={onAcceptSelected}
                    disabled={!selectedEvents.length}
                  >
                    Accept selected
                  </button>
                  <div className='form-group mt-md-3 mt-xl-0'>
                    <div
                      className='select-box'
                      style={{ margin: 0 }}
                    >
                      <label style={{ marginBottom: 0 }}>
                        <span>Status</span>
                        <Select
                          value={statusFilterJobProcessing}
                          onChange={handleStatusFilterChange}
                          options={logFilterOptions}
                          theme={selectTheme}
                          styles={selectStyles}
                        />
                      </label>
                    </div>
                    <div className='dates__item-picker ml-3'>
                      <p>Start time</p>
                      <ReactDatePicker
                        id='start-time-event'
                        dateFormat='dd/MM/yyyy'
                        className='form-control'
                        selected={processingStartDate}
                        onChange={onDateChange(
                          eventListingActions.setProcessingStartDate
                        )}
                        locale='enGb'
                        autoComplete='off'
                      />
                    </div>
                    <div className='dates__item-picker'>
                      <p>End time</p>
                      <ReactDatePicker
                        id='end-time-event'
                        dateFormat='dd/MM/yyyy'
                        className='form-control'
                        selected={processingEndDate}
                        onChange={onDateChange( eventListingActions.setProcessingEndDate )}
                        filterDate={time => {
                          return processingStartDate ? time > processingStartDate : true;
                        }}
                        locale='enGb'
                        autoComplete='off'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <EventItemHoverBoxContext.Provider
              value={{
                event: hoveredEvent,
                boundingBox: hoveredElementBoundingBox,
                setBoundingBox: setHoveredElementBoundingBox,
                setEvent: setHoveredEvent
              }}
            >
              <div className='col-12'>
                <table className='table compact'>
                  <thead>
                    <tr>
                      <th className='table-checkbox'>
                        <label className='custom-checkbox'>
                          <input
                            type='checkbox'
                            onChange={onSelectAll}
                            checked={selectAll}
                          />
                          <span />
                        </label>
                      </th>
                      <th
                        className='sortable'
                        onClick={toggleSorting( 'employee' )}
                        data-sort={
                          processingSortBy === 'employee'
                            ? processingSortOrder
                            : undefined
                        }
                      >
                        Employee
                      </th>
                      <th
                        className='sortable'
                        onClick={toggleSorting( 'startTime' )}
                        data-sort={
                          processingSortBy === 'startTime'
                            ? processingSortOrder
                            : undefined
                        }
                      >
                        Start time
                      </th>
                      <th>Hours</th>
                      <th>Customer</th>
                      <th
                        className='sortable'
                        onClick={toggleSorting( 'location' )}
                        data-sort={
                          processingSortBy === 'location'
                            ? processingSortOrder
                            : undefined
                        }
                      >
                        Location
                      </th>
                      <th
                        className='sortable'
                        onClick={toggleSorting( 'jobRole' )}
                        data-sort={
                          processingSortBy === 'jobRole' ? processingSortOrder : undefined
                        }
                      >
                        Job role
                      </th>
                      <th style={{ width: 80 }}>Comments</th>
                      <th>Phase status</th>
                      <th>Documents</th>
                    </tr>
                  </thead>
                  <tbody>
                    {visibleItems.map( event => (
                      <LogEventItem
                        event={event}
                        onSelect={onSelect}
                        checked={
                          !!selectedEvents.find( selected => selected.id === event.id )
                        }
                        key={event.id}
                      />
                    ) )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={theadData.length}>{PaginationComponent}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <LogItemHoverBox />
            </EventItemHoverBoxContext.Provider>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default JobLogsProcessingScreen;
