import { Field, Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import DatePickerField from '../../../components/DatePickerField';
import { Spinner } from '../../../components/Spinner';
import {
  employeesApi,
  useCreateEmployeeLeaveMutation,
  useEditLeaveMutation
} from '../../../store/apis/employeesApi';
import { createLeaveSchema } from '../../../store/apis/schemas';
import { FormRowSelect } from '../../NewOrderScreen/CreateOrderForm/FormRowSelect';
import { UnresolvedEvent } from './UnresolvedEvent';

export const CreateLeaveModal = ( { isOpen, setOpen, leaveToEdit } ) => {
  const { employeeId } = useParams();
  const dispatch = useDispatch();

  const [ createLeave ] = useCreateEmployeeLeaveMutation();
  const [ editLeave ] = useEditLeaveMutation();

  const [ unresolved, setUnresolved ] = useState();
  const [ resolved, setResolved ] = useState();
  const [ loading, setLoading ] = useState( false );

  const close = () => setOpen( false );

  const initialValues = {
    type: leaveToEdit?.type || 'sick',
    start: leaveToEdit?.start
      ? new Date( leaveToEdit?.start )
      : DateTime.now().startOf( 'day' ).toJSDate(),
    end: leaveToEdit?.end
      ? new Date( leaveToEdit?.end )
      : DateTime.now().plus( { days: 5 } ).startOf( 'day' ).toJSDate(),
    comment: leaveToEdit?.comment || ''
  };

  const onSubmit = values => {
    setLoading( true );
    const promise = leaveToEdit
      ? editLeave( {
          id: leaveToEdit.id,
          fields: values
        } )
      : createLeave( {
          id: employeeId,
          fields: values
        } );
    promise
      .unwrap()
      .catch( err => {
        console.log( { err } );
        setOpen( false );
      } )
      .then( response => {
        setLoading( false );
        if ( response?.unresolvedEvents ) {
          setUnresolved( { events: response.unresolvedEvents, token: response.token } );
          setResolved( [] );
        } else {
          setOpen( false );
        }
      } );
  };

  const addResolved = id => setResolved( resolved.concat( id ) );

  const renderSickLeaveCreate = () => (
    <>
      {loading && (
        <div className='overlay'>
          <Spinner />
        </div>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={createLeaveSchema}
        validateOnMount
      >
        {( { values, handleSubmit, handleChange, isSubmitting, isValid } ) => (
          <Form onSubmit={handleSubmit}>
            <FormRowSelect
              title='Type'
              name='type'
              options={[
                'sick',
                'parental',
                'holiday',
                'sick-pending',
                'annual-holiday',
                'annual-leave',
                'other'
              ].map( type => ( { value: type, label: type } ) )}
              style={{ textTransform: 'capitalize' }}
            />
            <div className='form-group'>
              <div className='form-row w-100'>
                <label htmlFor='start-time'>Start time</label>
                <DatePickerField
                  name='start'
                  id='start-time'
                  showTimeSelect
                  dateFormat='dd/MM/yyyy HH:mm'
                />
              </div>

              <div className='form-row w-100'>
                <label htmlFor='end-time'>End time</label>
                <DatePickerField
                  name='end'
                  id='end-time'
                  showTimeSelect
                  dateFormat='dd/MM/yyyy HH:mm'
                />
              </div>
            </div>

            <div className='form-row w-100'>
              <label htmlFor='start-time'>Comment</label>
              <Field
                as='textarea'
                id='comment'
                name='comment'
                className='form-control h-350'
                value={values.comment}
                onChange={handleChange}
                placeholder='Type message here...'
              />
            </div>
            <button
              className='btn btn--green w-100'
              type='submit'
              disabled={isSubmitting || !isValid}
            >
              Send
            </button>
          </Form>
        )}
      </Formik>
    </>
  );

  const renderUnresolvedEvents = () =>
    unresolved
      ? unresolved.events.map( ( event, index ) => (
          <UnresolvedEvent
            key={index}
            eventId={event.id}
            token={unresolved.token}
            onResolve={addResolved}
          />
        ) )
      : null;

  useEffect( () => {
    if ( unresolved && resolved ) {
      if ( unresolved.events.length === resolved.length ) {
        dispatch(
          employeesApi.util.invalidateTags( [
            { type: 'leaves', id: `employee${employeeId}` }
          ] )
        );
        setOpen( false );
      }
    }
  }, [ unresolved, resolved ] );

  return (
    <ReactModal
      isOpen={isOpen}
      style={{ content: { maxWidth: 800 } }}
      ariaHideApp={false}
      contentLabel='Edit Tasks'
      portalClassName='overflow-hidden'
    >
      <span
        className='icon-close'
        onClick={close}
      />
      <div className='modal-head'>
        <div className='modal-title'>
          {unresolved ? (
            <>
              <div>Unresolved events</div>
              <div style={{ fontSize: 11, fontWeight: 400 }}>
                (all events must be resolved)
              </div>
            </>
          ) : (
            'Create Employee Leave'
          )}
        </div>
      </div>
      <div className='modal-body'>
        {unresolved ? renderUnresolvedEvents() : renderSickLeaveCreate()}
      </div>
    </ReactModal>
  );
};
