import { mainApi } from './mainApi';

export const eventsApi = mainApi.injectEndpoints( {
  endpoints: build => ( {
    getJobEvent: build.query( {
      query: id => ( { url: `/jobEvents/${id}` } ),
      providesTags: ( res, err, arg ) => ( err ? [] : [ { type: 'event', id: arg } ] )
    } ),
    sendOffer: build.mutation( {
      query: ( { jobEventId, employeesIds } ) => ( {
        url: `/jobEvents/${jobEventId}/offer/send`,
        body: employeesIds,
        method: 'post'
      } ),
      invalidatesTags: ( res, err, arg ) =>
        err ? [] : [ { type: 'event', id: arg.jobEventId }, 'allEvents' ]
    } ),
    getJobEvents: build.query( {
      query: params => ( { url: '/jobEvents', params } ),
      providesTags: ( res, err ) => ( err ? [] : [ 'allEvents' ] )
    } ),
    createJobEvent: build.mutation( {
      query: values => ( {
        url: '/jobevents/_/order',
        body: {
          start: values.startTime,
          end: values.endTime,
          TaskIds: typeof values.task !== 'number'
            ? values.task.map( el =>  parseInt( el ) )
            : [ parseInt( values.task ) ],
          EventSkills: Array.isArray( values.eventSkills )
            ? values.eventSkills.map( el => {
                return { SkillId: parseInt( el ) };
              } )
            : [],
          JobOrder: {
            description: values.description,
            internalComment: values.internalComment
          }
        },
        method: 'post'
      } ),
      invalidatesTags: [ 'allEvents' ]
    } ),
    updateJobEvent: build.mutation( {
      query: ( { fields, eventId } ) => ( {
        url: `/jobEvents/${eventId}`,
        body: fields,
        method: 'put'
      } ),
      invalidatesTags: ( res, err, arg ) =>
        err ? [] : [ { type: 'event', id: arg.eventId }, 'allEvents' ]
    } ),
    updateJobOrder: build.mutation( {
      query: ( { eventId, description, internalComment } ) => ( {
        url: `/jobEvents/${eventId}/order`,
        body: ( () => {
          console.log( { eventId, description, internalComment } );
          return { description, internalComment };
        } )(),
        method: 'put'
      } ),
      invalidatesTags: ( res, err, arg ) =>
        err ? [] : [ { type: 'event', id: arg.eventId }, 'allEvents' ]
    } ),
    updateJobOffer: build.mutation( {
      query: ( { eventId, description } ) => ( {
        url: `/jobEvents/${eventId}/offer`,
        body: { description },
        method: 'put'
      } ),
      invalidatesTags: ( res, err, arg ) =>
        err ? [] : [ { type: 'event', id: arg.eventId }, 'allEvents' ]
    } ),
    submitJobLog: build.mutation( {
      query: ( { id, jobLog, decision, comment } ) => ( {
        url: `/jobEvents/${id}/jobLog/jobLink`,
        method: 'post',
        body: {
          ...jobLog,
          Joblink_Decision: decision,
          Joblink_Comment: comment
        }
      } ),
      invalidatesTags: ( res, err, arg ) =>
        err ? [] : [ { type: 'event', id: arg.id }, 'allEvents' ]
    } ),
    lockEvent: build.mutation( {
      query: id => ( {
        url: `/jobEvents/${id}/lock`,
        method: 'put'
      } ),
      invalidatesTags: ( res, err, id ) => ( err ? [] : [ { type: 'event', id } ] )
    } ),
    unlockEvent: build.mutation( {
      query: id => ( {
        url: `/jobEvents/${id}/unlock`,
        method: 'put'
      } ),
      invalidatesTags: ( res, err, id ) => ( err ? [] : [ { type: 'event', id } ] )
    } ),
    createOffer: build.mutation( {
      query: ( { eventId, description } ) => ( {
        url: `/jobEvents/${eventId}/offer`,
        method: 'post',
        body: { description }
      } ),
      invalidatesTags: ( res, err, { eventId } ) =>
        err ? [] : [ { type: 'event', id: eventId }, 'allEvents' ]
    } ),
    cancelOffer: build.mutation( {
      query: ( { eventId, comment } ) => ( {
        url: `/jobEvents/${eventId}/offer/cancel`,
        method: 'post',
        body: { comment }
      } ),
      invalidatesTags: ( res, err, { eventId } ) =>
        err ? [] : [ { type: 'event', id: eventId }, 'allEvents' ]
    } ),
    declineOffer: build.mutation( {
      query: eventId => {
        return {
          url: `/jobEvents/${eventId}/offer/decline`,
          method: 'post'
        };
      },
      invalidatesTags: ( res, err, { eventId } ) =>
        err ? [] : [ { type: 'event', id: eventId }, 'allEvents' ]
    } ),
    cancelOrder: build.mutation( {
      query: eventId => {
        console.log( 'order event' );
        return {
          url: `/jobEvents/${eventId}/order/cancel`,
          method: 'delete'
        };
      },
      invalidatesTags: ( res, err, { eventId } ) =>
        err ? [] : [ { type: 'event', id: eventId }, 'allEvents' ]
    } ),
    abortOffer: build.mutation( {
      query: ( { eventId, comment } ) => ( {
        url: `/jobEvents/${eventId}/offer/abort`,
        method: 'post',
        body: { comment }
      } ),
      invalidatesTags: ( res, err, { eventId } ) =>
        err ? [] : [ { type: 'event', id: eventId }, 'allEvents' ]
    } ),
    reactivateOffer: build.mutation( {
      query: id => ( {
        url: `/jobEvents/${id}/offer/reactivate`,
        method: 'put'
      } ),
      invalidatesTags: ( res, err, { eventId } ) =>
        err ? [] : [ { type: 'event', id: eventId }, 'allEvents' ]
    } ),
    acceptOffer: build.mutation( {
      query: ( { id, employeeId } ) => ( {
        url: `/jobevents/${id}/offer/forceAccept/${employeeId}`,
        method: 'POST',
      } ),
      invalidatesTags: ( res, err, { employeeId } ) =>
        err ? [] : [ { type: 'event', id: employeeId }, 'allEvents' ]
    } ),
    declineAcceptedOffer: build.mutation( {
      query: ( { id, employeeId } ) => ( {
        url: `/jobevents/${id}/offer/unsend/${employeeId}`,
        method: 'PUT',
      } ),
      invalidatesTags: ( res, err, { employeeId } ) =>
        err ? [] : [ { type: 'event', id: employeeId }, 'allEvents' ]
    } ),
    createPastJobEvent: build.mutation( {
      query: values => ( {
        url: '/jobevents/_/past',
        body: {
          start: values.startTime,
          end: values.endTime,
          JobRoleId: values?.jobRole,
          TaskIds: typeof values.task !== 'number'
            ? values.task.map( el =>  parseInt( el ) )
            : [ parseInt( values.task ) ],
          EventSkills: Array.isArray( values.eventSkills )
            ? values.eventSkills.map( el => {
                return { SkillId: parseInt( el ) };
              } )
            : [],
          JobOrder: {
            description: values.description,
            internalComment: values.internalComment
          },
          JobOffer: {
            description: '',
            AcceptedEmployeeId: values?.employeeId
          },
          JobLog: {
            Employee_Comment: '',
            Employee_Rating: '',
            Employee_Start: values.startTime,
            Employee_End: values.endTime,
            Employee_LunchBreak: 0,
            Employee_LunchBreakStart: '2021-01-01T00:00:00.000Z',
          }
        },
        method: 'post'
      } ),
      invalidatesTags: [ 'allEvents' ]
    } ),
  } )
} );

export const {
  useGetJobEventQuery,
  useSendOfferMutation,
  useGetJobEventsQuery,
  useCreateJobEventMutation,
  useUpdateJobEventMutation,
  useUpdateJobOrderMutation,
  useUpdateJobOfferMutation,
  useSubmitJobLogMutation,
  useLockEventMutation,
  useUnlockEventMutation,
  useCreateOfferMutation,
  useCancelOfferMutation,
  useCancelOrderMutation,
  useAbortOfferMutation,
  useReactivateOfferMutation,
  useDeclineOfferMutation,
  useAcceptOfferMutation,
  useDeclineAcceptedOfferMutation,
  useCreatePastJobEventMutation
} = eventsApi;
