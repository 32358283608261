import { useMemo } from 'react';
import { concat } from '../../../utils/utils';

export const EmployeeHoverBox = ( { employee, className } ) => {
  const employeeText = useMemo( () => {
    if ( employee ) {
      return `${employee.firstName} ${employee.lastName}`;
    }
  }, [ employee ] );

  return (
    <div className={concat( 'hover-box', className?.container )}>
      <div className={concat( 'header', className?.header )}>{employeeText}</div>
      <div className={concat( 'hover-event', className?.hoverEvent )}>
        <p>Clothing size</p>
        <p>{employee.clothingSize}</p>
      </div>
      <div className={concat( 'hover-event', className?.hoverEvent )}>
        <p>Internal Comment</p>
        <p className='for-tag-pad' > {employee.internalComment}</p>
      </div>
    </div>
  );
};
