import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Spinner } from '../../../../components/Spinner';
import { fieldErrorClass } from '../../../../constants';
import {
  useGetAllEmployeesQuery,
  useSetEmployeeSkillsMutation,
  useUpdateEmployeeMutation
} from '../../../../store/apis/employeesApi';
import validators from '../../../../utils/validators';
import { CertificateEdit } from './CertificateEdit';
import { CertificateUpload } from './CertificateUpload';
import { Skills } from './Skills';
import { Tasks } from './Tasks';

const EditPersonalInfoForm = ( { accountId } ) => {
  const history = useHistory();

  const { employeeId } = useParams();

  const allEmployees = useGetAllEmployeesQuery();
  const [ setEmployeeSkills ] = useSetEmployeeSkillsMutation();
  const [ updateEmployee ] = useUpdateEmployeeMutation();
  // const [ deleteCertificate ] = useDeleteCertificateMutation();

  const [ employeeSkillsIds, setEmployeeSkillsIds ] = useState( null );
  const [ loading, setLoading ] = useState( false );

  const currentEmployee = allEmployees.data?.find(
    employee => employee.id === +employeeId
  );

  useEffect( () => {
    if ( currentEmployee )
      setEmployeeSkillsIds( currentEmployee.Skills.map( skill => skill.id ) );
  }, [ currentEmployee ] );

  if ( !currentEmployee || !employeeSkillsIds ) return null;

  const initialValues = {
    phone: currentEmployee.phone,
    email: currentEmployee.email,
    CostCenterId: currentEmployee.CostCenterId,
    bankAccount: currentEmployee.bankAccount,
    idNumber: currentEmployee.idNumber,
    clothingSize: currentEmployee.clothingSize,
    address_Street: currentEmployee.address_Street,
    address_ZipCode: currentEmployee.address_ZipCode,
    address_City: currentEmployee.address_City,
    firstName: currentEmployee.firstName,
    lastName: currentEmployee.lastName,
    PresetTaskIds: currentEmployee.PresetTaskIds,
  };

  return (
    <>
      {loading && (
        <div className='overlay'>
          <Spinner />
        </div>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={( values, { setSubmitting } ) => {
          setLoading( true );
          Promise.all( [
            updateEmployee( { employeeId, fields: values } ),
            setEmployeeSkills( { employeeId, skillsIds: employeeSkillsIds } ),
          ] ).then( () => {
            setSubmitting( false );
            setLoading( false );
            history.goBack();
          } );
        }}
      >
        {( { values, handleChange, errors, touched, handleSubmit, isSubmitting } ) => (
          <Form
            onSubmit={handleSubmit}
            className='form edit-personal-info-form'
          >
            <div className='white-shadow-box mb-3 pb-3 pt-3'>
              <div className='form-group'>
                <div className='form-row w-100'>
                  <label htmlFor='phone'>First Name</label>
                  <Field
                    className={`form-control ${
                      errors.firstName && touched.firstName && fieldErrorClass
                    }`}
                    type='text'
                    id='firstName'
                    name='firstName'
                    validate={validators.required}
                    value={values.firstName}
                    onChange={handleChange}
                  />
                </div>
                <div className='form-row w-100 mr-3'>
                  <label htmlFor='email'>Last Name</label>
                  <Field
                    className={`form-control ${
                      errors.lastName && touched.lastName && fieldErrorClass
                    }`}
                    type='text'
                    id='lastName'
                    name='lastName'
                    validate={validators.required}
                    value={values.lastName}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='form-group'>
                <div className='form-row w-100'>
                  <label htmlFor='phone'>Phone</label>
                  <Field
                    className={`form-control ${
                      errors.phone && touched.phone && fieldErrorClass
                    }`}
                    type='text'
                    id='phone'
                    name='phone'
                    validate={validators.required}
                    value={values.phone}
                    onChange={handleChange}
                  />
                </div>
                <div className='form-row w-100 mr-3'>
                  <label htmlFor='email'>Email</label>
                  <Field
                    className={`form-control ${
                      errors.email && touched.email && fieldErrorClass
                    }`}
                    type='email'
                    id='email'
                    name='email'
                    validate={validators.required}
                    value={values.email}
                    onChange={handleChange}
                  />
                </div>
                <div className='form-row w-100'>
                  <label htmlFor='CostCenterId'>Cost center</label>
                  <Field
                    className={`form-control ${
                      errors.CostCenterId && touched.CostCenterId && fieldErrorClass
                    }`}
                    type='text'
                    id='CostCenterId'
                    name='CostCenterId'
                    validate={validators.required}
                    value={values.CostCenterId}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='form-group mb-3'>
                <div className='form-row w-100 mr-3'>
                  <label htmlFor='bankAccount'>Bank account</label>
                  <Field
                    className={`form-control ${
                      errors.bankAccount && touched.bankAccount && fieldErrorClass
                    }`}
                    type='text'
                    id='bankAccount'
                    name='bankAccount'
                    validate={validators.required}
                    value={values.bankAccount}
                    onChange={handleChange}
                  />
                </div>
                <div className='form-row w-100 mr-3'>
                  <label htmlFor='idNumber'>ID Number</label>
                  <Field
                    className={`form-control ${
                      errors.idNumber && touched.idNumber && fieldErrorClass
                    }`}
                    type='text'
                    id='idNumber'
                    name='idNumber'
                    validate={validators.required}
                    value={values.idNumber}
                    onChange={handleChange}
                  />
                </div>
                <div className='form-row w-100'>
                  <label htmlFor='clothingSize'>Clothing size</label>
                  <Field
                    className={`form-control ${
                      errors.clothingSize && touched.clothingSize && fieldErrorClass
                    }`}
                    type='text'
                    id='clothingSize'
                    name='clothingSize'
                    validate={validators.required}
                    value={values.clothingSize}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div>
                <div className='section-title text-bold'>Home address</div>
                <div className='form-group'>
                  <div className='form-row w-100 mb-0 mr-3'>
                    <label htmlFor='address_Street'>Street</label>
                    <Field
                      className={`form-control ${
                        errors.address_Street && touched.address_Street && fieldErrorClass
                      }`}
                      type='text'
                      id='address_Street'
                      name='address_Street'
                      validate={validators.required}
                      value={values.address_Street}
                      onChange={handleChange}
                    />
                  </div>
                  <div className='form-row w-100  mb-0 mr-3'>
                    <label htmlFor='address_ZipCode'>Postal Code</label>
                    <Field
                      className={`form-control ${
                        errors.address_ZipCode && touched.address_ZipCode && fieldErrorClass
                      }`}
                      type='text'
                      id='address_ZipCode'
                      name='address_ZipCode'
                      validate={validators.required}
                      value={values.address_ZipCode}
                      onChange={handleChange}
                    />
                  </div>
                  <div className='form-row w-100  mb-0'>
                    <label htmlFor='address_City'>City</label>
                    <Field
                      className={`form-control ${
                        errors.address_City && touched.address_City && fieldErrorClass
                      }`}
                      type='text'
                      id='address_City'
                      name='address_City'
                      validate={validators.required}
                      value={values.address_City}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div style={{ marginTop: 24 }}>
                <div className='section-title text-bold'>Task</div>
                <Tasks employeeId={employeeId}/>
              </div>
              <div style={{ marginTop: 24 }}>
                <div className='section-title text-bold'>Skills</div>
                <Skills
                  checked={employeeSkillsIds}
                  onChange={skillIds => setEmployeeSkillsIds( skillIds )}
                />
              </div>
              <div style={{ marginTop: 24 }}>
                <div className='section-title text-bold'>Certificates</div>
                <CertificateUpload employeeId={employeeId} />
                <div className='certificates-wrapper edit mt-2'>
                  {currentEmployee.Certificates.map( ( certificate, index ) => (
                    <CertificateEdit
                      certificate={certificate}
                      key={index}
                    />
                  ) )}
                </div>
              </div>
            </div>
            <div className='form-footer d-flex justify-content-between align-items-center'>
              <div className='btn-group'>
                <button
                  className='btn btn--green'
                  type='submit'
                  disabled={isSubmitting}
                >
                  Send
                </button>
                <button
                  className='btn btn--default'
                  onClick={history.goBack}
                  type='button'
                >
                  Cancel
                </button>
              </div>
              <button
                type='button'
                className='btn btn--light-danger btn--disabled'
                onClick={() => console.log( `deleteAccount(${accountId})` )}
              >
                Delete account
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EditPersonalInfoForm;
