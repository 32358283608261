import React, { useEffect, useMemo, useState } from 'react';
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import Select from 'react-select';
import ErrorBoundary from '../../components/ErrorBoundary';
import NoResultInTable from '../../components/NoResultInTable';
import { SearchPanel } from '../../components/SearchPanel';
import { Spinner } from '../../components/Spinner';
import { usePagination } from '../../hooks/usePagination';
import { searchByFirstAndLastName } from '../../store/actions/appActions';
import { useGetCostCentersQuery } from '../../store/apis/costCentersApi';
import { useGetAllEmployeesQuery } from '../../store/apis/employeesApi';
import { selectTheme } from '../../theme';
import InviteModal from './InviteModal';
import { employeesSelectOptions, theadData } from './data';

const Employees = () => {
  // Other Hooks
  const { url } = useRouteMatch();
  const history = useHistory();

  const { data: costCenters, isLoading } = useGetCostCentersQuery();

  // States
  const [ selectedCostCenter, setSelectedCostCenter ] = useState( {
    value: 'all',
    label: 'All Cost centers',
  } );
  const [ employeesFilterOptions, setEmployeesFilterOptions ] =
    useState( employeesSelectOptions );
  const [ modalIsOpen, setIsOpen ] = useState( false );
  const [ modalData, setModalData ] = useState( null );
  const [ accountStatus, setAccountStatus ] = useState( 'active' );
  const [ statusSort, setStatusSort ] = useState();
  const [ searchTerm, setSearchTerm ] = useState( '' );

  const employees = useGetAllEmployeesQuery( {
    filters: {
      statuses:
        accountStatus === 'all' ? [ 'active', 'locked', 'blocked' ] : [ accountStatus ],
    },
    includes: {
      Skills: true,
      Certificates: true,
    },
  } );

  const costCentersOptions = useMemo( () => {
    let options = [ { value: 'all', label: 'All Cost centers' } ];
    if ( costCenters ) {
      options = [
        ...options,
        ...costCenters.map( center => ( { value: center?.id, label: center?.name } ) ),
      ];
    }
    return options;
  }, [ costCenters ] );

  // Actions
  const handleCostCenterNameChange = selectedOption =>
    setSelectedCostCenter( selectedOption );
  const handleEmployeesFilterChange = selectedOption =>
    setEmployeesFilterOptions( selectedOption );
  const openModal = () => setIsOpen( true );
  const closeModal = () => setIsOpen( false );
  const onAccountStatusChange = option => setAccountStatus( option.value );
  const toggleStatusSort = isDesc => () => setStatusSort( isDesc ? 'asc' : 'desc' );

  const filteredItems = useMemo( () => {
    if ( !employees.data ) return [];
    let unfiltered = searchByFirstAndLastName( employees.data, searchTerm );
    if ( selectedCostCenter.value !== 'all' ) {
      unfiltered = unfiltered.filter(
        employee => employee.CostCenterId === selectedCostCenter.value
      );
    }
    if ( accountStatus !== 'all' ) {
      unfiltered = unfiltered.filter( employee => employee.status === accountStatus );
    }
    if ( statusSort )
      unfiltered = unfiltered.sort(
        ( a, b ) => ( a.status > b.status ? 1 : -1 ) * ( statusSort === 'asc' ? 1 : -1 )
      );
    return unfiltered;
  }, [ accountStatus, employees.data, selectedCostCenter.value, statusSort, searchTerm ] );

  const { visibleItems, PaginationComponent } = usePagination( filteredItems );

  useEffect( () => {
    setModalData( costCenters );
  }, [ costCenters ] );

  return (
    <ErrorBoundary>
      <div className='app-content'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <h1 className='title d-block error-title'>Employees</h1>
            </div>
            <div className='col-12 mt-4 mt-lg-0 mb-4 mb-md-2 mb-lg-5'>
              <div className='d-flex justify-content-between align-items-end'>
                <div className='filters d-md-flex align-items-center'>
                  <div className='filters__body'>
                    <div className='filter-group align-items-end'>
                      <div className='select-box'>
                        <label>
                          <Select
                            value={selectedCostCenter}
                            onChange={handleCostCenterNameChange}
                            options={costCentersOptions}
                            theme={selectTheme}
                          />
                        </label>
                      </div>
                      <div className='select-box'>
                        <label>
                          <Select
                            value={{
                              value: accountStatus,
                              label: accountStatus,
                            }}
                            options={[
                              { value: 'active', label: 'Active' },
                              { value: 'blocked', label: 'Blocked' },
                              { value: 'locked', label: 'Locked' },
                              { value: 'all', label: 'Show All' },
                            ]}
                            onChange={onAccountStatusChange}
                            theme={selectTheme}
                            styles={{
                              singleValue: base => ( {
                                ...base,
                                textTransform: 'capitalize',
                              } ),
                            }}
                          />
                        </label>
                      </div>
                      <div
                        className='select-box'
                        style={{ display: 'none' }}
                      >
                        <label>
                          <Select
                            value={employeesFilterOptions[0]}
                            onChange={handleEmployeesFilterChange}
                            options={employeesSelectOptions}
                            theme={selectTheme}
                          />
                        </label>
                      </div>
                      <div className='search mr-3'>
                        <SearchPanel
                          placeholder='Search...'
                          value={searchTerm}
                          onChange={setSearchTerm}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    className='btn btn-md-block btn--green'
                    onClick={() => openModal()}
                  >
                    Send invite
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <table className='table'>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th
                      className='sortable'
                      data-sort={statusSort}
                      onClick={toggleStatusSort( statusSort === 'desc' )}
                    >
                      Status
                    </th>
                    <th>Email</th>
                    <th>Phone</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td
                        className='h-15'
                        colSpan={theadData.length}
                      >
                        <Spinner />
                      </td>
                    </tr>
                  ) : visibleItems.length ? (
                    visibleItems.map( employee => (
                      <tr key={employee.id}>
                        <td className='link-in text-bold'>
                          <NavLink to={`${url}/${employee.id}`}>
                            {employee.firstName} {employee.lastName}
                            <span
                              className='send-message-icon ml-2'
                              onClick={() => history.push( '/messages' )}
                            >
                              <svg
                                width='18'
                                height='16'
                                viewBox='0 0 18 16'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M17.4167 14.3389C17.4152 14.5468 17.332 14.7458 17.1851 14.8929C17.0382 15.04 16.8392 15.1233 16.6313 15.125H2.36867C2.16031 15.1248 1.96056 15.0419 1.8133 14.8945C1.66605 14.7471 1.58333 14.5472 1.58333 14.3389V13.5417H15.8333V4.27917L9.5 9.97917L1.58333 2.85417V1.66667C1.58333 1.4567 1.66674 1.25534 1.81521 1.10687C1.96367 0.958407 2.16504 0.875 2.375 0.875H16.625C16.835 0.875 17.0363 0.958407 17.1848 1.10687C17.3333 1.25534 17.4167 1.4567 17.4167 1.66667V14.3389ZM3.51025 2.45833L9.5 7.84958L15.4898 2.45833H3.51025ZM0 10.375H6.33333V11.9583H0V10.375ZM0 6.41667H3.95833V8H0V6.41667Z'
                                  fill='#22C55E'
                                />
                              </svg>
                            </span>
                          </NavLink>
                        </td>
                        <td className='link-in'>
                          <NavLink to={`${url}/${employee.id}`}>
                            {employee.status}
                          </NavLink>
                        </td>
                        <td className='link-in'>
                          <NavLink to={`${url}/${employee.id}`}>{employee.email}</NavLink>
                        </td>
                        <td className='link-in'>
                          <NavLink to={`${url}/${employee.id}`}>{employee.phone}</NavLink>
                        </td>
                      </tr>
                    ) )
                  ) : (
                    <NoResultInTable colSpan={theadData.length} />
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={theadData.length}>{PaginationComponent}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
      <InviteModal
        data={modalData}
        modalIsOpen={modalIsOpen}
        maxWidth={680}
        closeModal={closeModal}
        response={null}
      />
    </ErrorBoundary>
  );
};

export default Employees;
