import styles from '../../OperationsScreen.module.sass';
import { BadgeHoverBox } from './BadgeHoverBox';
import { BadgeHoverBoxEvent } from './BadgeHoverBoxEvent';

export const HoverBoxAbandoned = ( { events, date, className, crossedOut } ) => {
  const uniqueEvents = events.filter( ( event, index, self ) =>
    index === self.findIndex( ( e ) => e.id === event.id )
  );

  return (
    <BadgeHoverBox
      date={date}
      className={className}
    >
      {uniqueEvents?.map( event => (
        <BadgeHoverBoxEvent
          event={event}
          className={crossedOut.includes( event.id ) ? styles['crossed-out'] : undefined}
          key={event.id}
        />
      ) )}
    </BadgeHoverBox>
  );
};
