import React, { memo, useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { useLocation } from 'react-router-dom';
import ErrorBoundary from '../../components/ErrorBoundary';
import LinkBack from '../../components/LinkBack';
import { useGetCostCentersQuery } from '../../store/apis/costCentersApi';
import CreateOrderForm from './CreateOrderForm';
import AddPastEventForm from './CreateOrderForm/AddPastEventForm';

const NewOrder = () => {
  const { data: costCenters } = useGetCostCentersQuery();
  const location = useLocation();
  const previousPath = location.state?.from;

  const [ isEditModalOpened, setEditModalOpened ] = useState( false );

  const closeEditModal = () => {
    setEditModalOpened( false );
  };

  useEffect( () => {
    localStorage.clear();
    return () => localStorage.clear();
  }, [] );

  const customStyles = {
    content: {
      maxWidth: 720
    }
  };

  useMemo( () => {
    if ( previousPath === '/operations' ) {
      setEditModalOpened( true );
    } 
  }, [ previousPath ] );

  return (
    <ErrorBoundary>
      <div className='app-content'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12 col-md-6'>
              <LinkBack title='Back to Event listing' />
              <div className='d-flex align-items-center'>
                <h1 className='title mt-3 mb-4'>Create new order</h1>
                <button
                  className='btn btn--green ml-4'
                  type='submit'
                  onClick={() => setEditModalOpened( true )}
                >
                  Add event in the past
                </button>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 mb-4'>
              <CreateOrderForm costCenters={costCenters} />
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isEditModalOpened}
        style={customStyles}
        ariaHideApp={false}
      >
        <div className='modal-head'>
          <div className='modal-title'>
            <h2 className='title'>Add past event</h2>
          </div>
          <AddPastEventForm onClose={closeEditModal} />
        </div>
      </Modal>
    </ErrorBoundary>
  );
};

export default memo( NewOrder );
