import { Field, Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Spinner } from '../../../components/Spinner';
import { useGetCostCentersQuery } from '../../../store/apis/costCentersApi';
import {
  useCreateJobEventMutation,
  useCreateOfferMutation
} from '../../../store/apis/eventsApi';
import {
  CostCenterField,
  DescriptionField,
  InternalCommentField,
  JobRoleField,
  LocationField,
  SkillsField,
  TaskField,
} from './components';
import { EndTimeField } from './components/EndTimeField';
import { StartTimeField } from './components/StartTimeField';
import { createOrderFormSchema } from './formValidation';

const CreateOrderForm = () => {
  const history = useHistory();

  const { data: costCenters } = useGetCostCentersQuery();
  const [ createOffer ] = useCreateOfferMutation();
  const [ isLoading, setIsLoading ] = useState( false );

  const initStartTime = useMemo(
    () => DateTime.now(),
    []
  );

  const initialValues = {
    costCenter: '',
    location: '',
    company: '',
    jobRole: '',
    task: '',
    startTime: new Date( Math.ceil( Date.parse( initStartTime ) / 3600000 ) * 3600000 ),
    endTime: new Date( Math.ceil( Date.parse( initStartTime ) / 3600000 ) * 3600000 + 6 * 3600000 ),
    eventSkills: [],
    description: '',
    internalComment: '',
    open: false
  };
  const [ createJobEvent ] = useCreateJobEventMutation();

  if ( !costCenters ) return <Spinner />;

  const onSubmit = ( values, { setSubmitting } ) => {
    setIsLoading( true );
    createJobEvent( values )
      .unwrap()
      .then( ( { id } ) => {
        if ( values.open && id ) {
          createOffer( { eventId: id, description: values.description } )
            .unwrap()
            .then( () => {
              setSubmitting( false );
              history.push( `/event-listing/${id}` );
              setIsLoading( false );
            } )
            .catch( ( ) => {
              setIsLoading( false );
            } );
        } else {
          setSubmitting( false );
          history.goBack();
          setIsLoading( false );
        }
      } )
      .catch( ( ) => {
        setIsLoading( false );
      } );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={createOrderFormSchema}
      enableReinitialize={true}
    >
      {( { isSubmitting, isValid, setFieldValue, dirty, values } ) => (
        <Form className='white-shadow-box pb-4'>
          {isLoading && (
            <div className='overlay'>
              <Spinner />
            </div>
          )}
          <div className='form__body'>
            <div className='form-group'>
              <LocationField />
              <CostCenterField />
            </div>
            <div className='form-group'>
              <JobRoleField />
              <TaskField />
            </div>
            <div className='form-group'>
            <div className='d-flex align-items-start flex-column'>
              <label>Time</label>
              <div className='d-flex'>
                <StartTimeField eventTime={false}/>
                <EndTimeField
                  eventDate={false}
                  eventTime={false}
                  autoSelectDate={true}
                />
              </div>
            </div>
            </div>
            <div className='form-group'>
              <SkillsField defaultValues={true} />
            </div>
            <div className='form-group'>
              <DescriptionField />
              <InternalCommentField />
            </div>
          </div>
          <div className='form-footer'>
            <div className='mt-4'>
              <div className='form-group align-items-center'>
                <button
                  className='btn btn--green'
                  type='submit'
                  disabled={isSubmitting || ( !isValid && !dirty ) || !values.location}
                >
                  Save
                </button>
                <button
                  className='btn btn--light-green'
                  type='submit'
                  disabled={isSubmitting || ( !isValid && !dirty ) || !values.location}
                  onClick={() => setFieldValue( 'open', true )}
                >
                  Save & Open
                </button>
                <div
                  className='checkboxes-item ml-md-1'
                  style={{ display: 'none' }}
                >
                  <label className='custom-checkbox custom-checkbox--default-color mt-4 mt-md-2 ml-0'>
                    <Field
                      type='checkbox'
                      name='OfferAccepted'
                    />
                    <span className='ml-1'>
                      Send notification when Job Offer will be Accepted
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateOrderForm;
