import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { useContext, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/chatMessage-delete.svg';
import { ReactComponent as MailIcon } from '../../../../assets/images/chatMessage-mail.svg';
import { ReactComponent as ReplyIcon } from '../../../../assets/images/chatMessage-message.svg';
import { ReactComponent as EditIcon } from '../../../../assets/images/edit-icon.svg';
import { Button } from '../../../../components/Button';
import { createConversationActions } from '../../../../store/slices';
import { ChatContext } from '../ChatScreen';
import './chatMessage.sass';

const ChatMessage = ( { editSingleMessage, deleteSingleMessage, message, conversation } ) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { replyToMessage, messages, scrollToMessage } = useContext( ChatContext );

  const { text, JoblinkGroupId, createdAt, ReplyToId } = message;
  const [ editText, setEditText ] = useState( text );
  const [ showEditMessage, setShowEditMessage ] = useState( false );

  const date = DateTime.fromISO( createdAt ).toFormat( 'hh:mm' );

  const onReply = () => {
    replyToMessage( message );
  };

  const onReplyPrivate = () => {
    const employee = message.EmployeeId
      ? conversation.Employees.find( item => item.id === message.EmployeeId )
      : undefined;
    if ( employee ) {
      dispatch( createConversationActions.setEmployeesPrefilled( employee ) );
      history.push( '/chat/create' );
    }
    dispatch( createConversationActions.setMessageToReply( message ) );
    dispatch( createConversationActions.setJoblinkGroupId( conversation.JoblinkGroupId ) );
  };

  const repliedMesssage = useMemo( () => {
    if ( !ReplyToId ) return undefined;
    return messages.find( msg => msg.id === ReplyToId );
  }, [ ReplyToId ] );

  const isStaffMessage = !!JoblinkGroupId;

  const userLabel = useMemo( () => {
    return message.JoblinkGroupId
      ? 'You'
      : message.CustomerUserId
      ? 'Company User'
      : 'Employee';
  }, [ message ] );

  const onEditMessage = () => {
    editSingleMessage( message.id, editText, ReplyToId );
    setShowEditMessage( false );
  };

  return (
    <div
      className={[ 'chatMessage', isStaffMessage ? 'me' : undefined ].join( ' ' )}
      data-id={message.id}
    >
      <div className='chatMessage__top'>
        <div className='chatMessage__column'>
          <span className='chatMessage__user'>{userLabel}</span>
        </div>
        <div className='chatMessage__column'>
          <div className='chatMessage__actions'>
            <ReplyIcon onClick={onReply} />
            {!isStaffMessage && <MailIcon onClick={onReplyPrivate} />}
            <EditIcon onClick={() => {ReplyToId && setShowEditMessage( true );}} />
            <DeleteIcon onClick={() => deleteSingleMessage( message.id )} />
          </div>
          <span className='chatMessage__time'>{date}</span>
        </div>
      </div>
      {repliedMesssage ? (
        <div
          className='chatMessage__middle messageAnswer'
          onClick={scrollToMessage( repliedMesssage.id )}
        >
          <p className='messageAnswer__name'>
            {repliedMesssage.JoblinkGroupId ? 'You' : 'Employee'}
          </p>
          <p className='messageAnswer__message'>{repliedMesssage.text}</p>
        </div>
      ) : null}
      {showEditMessage ? (
        <div className='d-flex flex-column'>
          <input
            className='chatMessage__editInput'
            value={editText}
            onChange={( e ) => setEditText( e.target.value )}
          />
          <div className='mt-3'>
            <Button
              className='chatMessage__saveButton'
              type='text'
              onClick={onEditMessage}
            >
              Save
            </Button>
            <Button
              className='chatMessage__cancelButton ml-3'
              type='text'
              onClick={() => setShowEditMessage( false )}
            >
              Cancel
            </Button>
          </div>
        </div>
         ) : (
        <div className='chatMessage__bottom'>
          <p>{text}</p>
        </div> 
      )}
    </div>
  );
};

ChatMessage.propTypes = {
  id: PropTypes.number,
  me: PropTypes.bool,
  answer: PropTypes.bool,
  name: PropTypes.string,
  img: PropTypes.string,
  textMessage: PropTypes.string
};

export default ChatMessage;
