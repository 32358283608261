import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import Select from 'react-select';
import { useGetEmployeeQuery } from '../../../../store/apis/employeesApi';
import { useGetPresetTasksQuery } from '../../../../store/apis/presetTasksApi';

export const Tasks = ( { employeeId } ) => {
  const { data: employeeFetched } = useGetEmployeeQuery(
    {
      id: employeeId,
      params: { Tasks: true }
    }
  );
  const presetTasks = useGetPresetTasksQuery();
  const { setFieldValue } = useFormikContext();
  const [ value, setValue ] = useState( [] );
  const [ onChange, setOnChange ] = useState( false );

  const onSelectChange = objs => {
    setOnChange( true );
    setValue( objs );
    setFieldValue( 'PresetTaskIds', objs.map( obj => obj.value ) );
  };

  return (
    <Select
      options={presetTasks.data?.map( ( task ) => ( {
        value: task.id,
        label: task.name,
      } ) )}
      onChange={onSelectChange}
      isMulti
      placeholder='Select tasks'
      className='select'
      classNamePrefix='select'
      value={onChange ? value : employeeFetched?.PresetTasks.map( task => ( {
        value: task.id,
        label: task.name,
      } ) )}
    />
  );
};
