import { createSlice } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

const initialState = {
  currentWeekStart: DateTime.now().startOf( 'week' ),
  costCenter: 'all',
  weeksCount: 2,
  viewMode: 'jobRole',
  employeeFilter: undefined,
  locationFilter: undefined,
  allEvents: [],
  eventsByDate: {}
};

export const operationsPageSlice = createSlice( {
  initialState,
  name: 'operationsPage',
  reducers: {
    setCurrentWeekStart: ( state, action ) => {
      state.currentWeekStart = action.payload;
    },
    setCostCenter: ( state, action ) => {
      state.costCenter = action.payload;
    },
    setWeeksCount: ( state, action ) => {
      state.weeksCount = action.payload;
    },
    setViewMode: ( state, action ) => {
      state.viewMode = action.payload;
    },
    setEmployeeFilter: ( state, action ) => {
      state.employeeFilter = action.payload;
    },
    setLocationFilter: ( state, action ) => {
      state.locationFilter = action.payload;
    },
    setAllEvents: ( state, action ) => {
      state.allEvents = action.payload ? [ ...action.payload ].sort(
        ( a, b ) => DateTime.fromISO( a.start ) > DateTime.fromISO( b.start ) ? 1 : -1
      ) : [];
    },
    setEventsByDate: ( state, action ) => {
      state.eventsByDate = action.payload;
    }
  }
} );

export const operationsPageActions = operationsPageSlice.actions;
