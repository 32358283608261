import { DateTime } from 'luxon';
import { memo, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from '../../OperationsScreen.module.sass';
import { BadgeHoverBox } from './BadgeHoverBox';
import { OperationEventBadge } from './OperationEventBadge';

const OperationBadgeComponent = ( {
  icon: Icon,
  events,
  employee,
  jobRole,
  filterLocationJobRole,
  jobRoleGroups,
  availability
} ) => {
  const { pathname } = useLocation();
  const hasEventsInOtherJobRoles = useMemo( () => {
    if ( !jobRoleGroups || !jobRole ) return false;
    const otherJobRolesGroups = jobRoleGroups.filter(
      group => group.jobRole.id !== jobRole
    );
    const date = DateTime.fromISO( events[0].start ).toISODate();

    const accepted = otherJobRolesGroups
      .map(
        group =>
          group.byEmployee.find( item => item.employee.id === employee.id )?.jobEvents
            .byDate.accepted[date] || []
      )
      .flat();

    if ( accepted.length )
      return {
        status: 'accepted',
        jobEvents: accepted
      };

    const received = otherJobRolesGroups
      .map(
        group =>
          group.byEmployee.find( item => item.employee.id === employee.id )?.jobEvents
            .byDate.received[date] || []
      )
      .flat();

    if ( received.length )
      return {
        status: 'offered',
        jobEvents: received
      };

    return false;
  }, [ jobRole, jobRoleGroups ] );

  const startTime = ( DateTime.fromISO( availability?.start ) ).toFormat( 'hh:mm' );
  const endTime = DateTime.fromISO( availability?.end ).toFormat( 'hh:mm' );
  const filteredEvents = useMemo( () => {
    const currentDate = new Date();
    return events?.filter( ( event ) => {
      if ( event?.JobOffer?.AcceptedEmployeeId === null ) {
        if ( new Date( event?.end ) < currentDate ) {
          return false;
        }
      }

      return true;
    } );
  }, [ events ] );

  return (
    <div className={styles['badge']}>
      {filteredEvents ? (
        <Link 
          to={{ pathname: `/accounts-employees/${employee.id}`, 
          state: { from: pathname } }}
        >
          <OperationEventBadge
            events={[ filteredEvents ].flat()}
            employee={employee}
            filterLocationJobRole={filterLocationJobRole}
            hasEventsInOtherJobRoles={hasEventsInOtherJobRoles}
          />
        </Link>
      ) : (
        <div>
          <Icon />
          {availability && <BadgeHoverBox>
            <p>Työntekijän vapaat työajat</p>
            <p>{startTime}-{endTime}</p>
          </BadgeHoverBox>}
        </div>
      )}
    </div>
  );
};

export const OperationBadge = memo( OperationBadgeComponent );
