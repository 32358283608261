import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { concat, roundHalf } from '../../../../utils/utils';
import styles from '../../OperationsScreen.module.sass';

export const BadgeHoverBoxEventJobRole = ( { event, className, jobRole, location, showLocationJobRoleHeading } ) => {
  const start = useMemo( () => DateTime.fromISO( event.start ), [ event ] );
  const end = useMemo( () => DateTime.fromISO( event.end ), [ event ] );
  const hoursDiff = useMemo( () => roundHalf( end.diff( start, 'hours' ).hours ) );

  const employees = useMemo( () => {
    const offer = event.JobOffer;
    return offer?.ReceivedEmployees.map( employee => ( {
      status: employee.SentEmployees.type,
      employee: employee
    } ) );
  }, [ event ] );

  const sortedEmployees = [ ...( employees ?? [] ) ].sort( ( a, b ) => {
    const aName = a.employee.lastName.concat( a.employee.firstName );
    const bName = b.employee.lastName.concat( b.employee.firstName );
    return aName.localeCompare( bName );
  } );

  const hasMoreTenEmployees = useMemo( () => sortedEmployees?.length > 10, [ sortedEmployees ] );

  const isAccepted = useMemo( () => !!event.JobOffer?.AcceptedEmployee, [ event ] );

  const render = () => (
    <>
       {showLocationJobRoleHeading && <p>{location} - {jobRole}</p> }
      <p>
        <span>
          {start.toFormat( 'HH:mm' )} - {end.toFormat( 'HH:mm' )}
        </span>
        <span>{hoursDiff} hours</span>
      </p>
      <p className={styles['employees']}>
        {sortedEmployees?.length ? (
          sortedEmployees.slice( 0, 10 ).map( ( { employee, status } ) => (
            <span
              key={employee.id}
              className={
                styles[isAccepted ? ( status === 'accept' ? status : 'rejected' ) : status]
              }
            >
              {employee.lastName}, {employee.firstName}
            </span>
          ) )
          ) : (
            <span>{event.status === 'open' ? 'Open' : 'Unprocessed'}</span>
            )}
          {hasMoreTenEmployees && <p className={styles['assign-employee']}>{sortedEmployees.length - 10} lisää</p>}
      </p>
    </>
  );

  return (
    <Link
      to={`/event-listing/${event.id}`}
      className={concat( styles['hover-event'], className )}
    >
      {render()}
    </Link>
  );
};
