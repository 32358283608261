import { SearchPanel } from '../../../components/SearchPanel';
import { StyledSelect } from '../../../components/StyledSelect';
import { useGetCostCentersQuery } from '../../../store/apis/costCentersApi';
import styles from '../EmployeeAssignmentScreen.module.scss';

export const Filters = ( {
  costCenter,
  setCostCenter,
  availability,
  setAvailability,
  searchTerm,
  setSearchTerm
} ) => {
  const { data: costCenters } = useGetCostCentersQuery();

  return (
    <div className='row mb-4'>
      <div className='col-12'>
        <div className='filters'>
          <div className='filters__body align-items-end flex-wrap flex-xl-nowrap'>
            <div className='form-group mt-md-3 mt-xl-0'>
              <label className='filter-box filter-box-small custom-checkbox d-none'>
                <input
                  name='costCenterName'
                  type='checkbox'
                />
                <span>Selected Cost Center</span>
              </label>
              <div className='select-box w-auto'>
                <label>
                  <StyledSelect
                    value={costCenter}
                    onChange={setCostCenter}
                    options={[
                      { value: 'all', label: 'All Cost centers' },
                      ...( costCenters || [] ).map( center => ( {
                        value: center.id,
                        label: center.name
                      } ) )
                    ]}
                  />
                </label>
              </div>
              <div className='select-box w-auto'>
                <label>
                  <StyledSelect
                    value={availability}
                    onChange={setAvailability}
                    options={[
                      { value: 'all', label: 'All employees' },
                      { value: 'certainlyAvailable', label: 'Available' },
                      { value: 'certainlyUnavailable', label: 'Unavailable' },
                      { value: 'partiallyAvailable', label: 'Partially Available' }
                    ]}
                  />
                </label>
              </div>
              <SearchPanel
                value={searchTerm}
                onChange={setSearchTerm}
                className={styles['search']}
                placeholder={'after 3 letters try to look employee names'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
