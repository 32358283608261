import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';
import { ReactComponent as AddIcon } from '../../../../assets/images/operations/add-icon.svg';
import styles from '../../OperationsScreen.module.sass';
import { BadgeHoverBox } from './BadgeHoverBox';
import { BadgeHoverBoxEventJobRole } from './BadgeHoverBoxEventJobRole';

export const HoverBoxJobRole = ( { events, date, jobRole, location, className, showLocationJobRoleHeading } ) => {
  const history = useHistory();

  const goCreateOrder = () => {
    history.push( '/new-order', {
      costCenterId: location?.CostCenterId,
      locationId: location?.id,
      jobRoleId: jobRole?.id,
      companyId: location?.CompanyAccount.name,
      date: date
        .set( {
          hour: DateTime.now().hour
        } )
        .toJSDate()
    } );
  };

  return (
    <BadgeHoverBox
      date={date}
      className={className}
      jobRole={jobRole}
    >
      {events.map( event => (
        <div key={event?.id}>
          <BadgeHoverBoxEventJobRole
            event={event}
            key={event.id}
            jobRole={event.JobRole?.name}
            location={event.JobRole?.Location?.name}
            showLocationJobRoleHeading={showLocationJobRoleHeading}
          />
          <p className={styles['comment']}>{event?.JobOrder?.description}</p>
          <p className={styles['comment']}>{event?.JobOrder?.internalComment}</p>
        </div>
      ) )}
      <div
        onClick={goCreateOrder}
        className={styles['button-create']}
      >
        <AddIcon />
        Create order
      </div>
    </BadgeHoverBox>
  );
};
