import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { groupJobEventsByJobRole } from '../../../../utils/helpers';
import { concat } from '../../../../utils/utils';
import styles from '../../OperationsScreen.module.sass';
import { HeaderRow } from '../header';
import { JobRoleGroup } from '../rows/JobRoleGroup';

export const OperationsJobRoleLayout = ( { tableHeight, visible } ) => {
  const { allEvents, weeksCount, currentWeekStart, costCenter, employeeFilter } = useSelector(
    state => state.operationsPage
  );

  const filteredEvents = allEvents?.filter( ( item ) => {
    let status = true;

    if ( costCenter !== 'all' ) {
      status = item?.JobRole?.Location?.CostCenterId === costCenter;
    }
    if ( employeeFilter && status ) {
      status = item?.JobOffer?.ReceivedEmployees?.some( ( employee ) => employee?.id === employeeFilter?.id ) ||
        item?.JobOffer?.AcceptedEmployee?.id === employeeFilter?.id;
    }

    return status;
  } );

  const groups = useMemo( () => groupJobEventsByJobRole( filteredEvents ), [ filteredEvents ] );

  const jobRoleFilter = useMemo( () => {
    return groups?.filter( ( group ) =>
      group.jobEvents.some( ( jobEvent ) =>
        [ 'unprocessed', 'open', 'filled', 'completed' ].includes( jobEvent.status )
      )
    );
  }, [ groups ] );

  const [ scrollInfo, setScrollInfo ] = useState( { scrollLeft: 0, scrollTop: 0 } );
  const [ scrollHorizontal, setScrollHorizontal ] = useState( false );

  const handleScroll = ( event ) => {
    const element = event.target;
    setScrollInfo( { scrollLeft: element.scrollLeft, scrollTop: element.scrollTop } );

    if ( Math.abs( element.scrollLeft - scrollInfo.scrollLeft ) < Math.abs( element.scrollTop - scrollInfo.scrollTop ) ) {
      setScrollHorizontal( true );
    } else {
      setScrollHorizontal( false );
    }
  };

  return (
    <>
      <div
        className={concat( styles['table'], styles[tableHeight] )}
        data-weeks={weeksCount}
        onScroll={handleScroll}
      >
        <HeaderRow
          week={currentWeekStart}
          sticky={'header-sticky'}
          time={'header-stickyTime'}
          visible={visible}
          scrollHorizontal={scrollHorizontal}
        />
        {jobRoleFilter?.map( ( group, index ) => (
          <JobRoleGroup
            key={index}
            jobRole={group.jobRole}
            location={group?.jobRole.Location}
            jobRoleGroups={groups}
            employees={group?.byEmployee?.map( ( employee ) => employee?.employee )}
          />
        ) )}
      </div>
    </>
  );
};
