import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import Select from 'react-select';
import { useGetPresetTasksQuery } from '../../../../store/apis/presetTasksApi';

export const TasksField = ( ) => {
  const presetTasks = useGetPresetTasksQuery();
  const { setFieldValue } = useFormikContext();
  const [ value, setValue ] = useState( [] );

  const onSelectChange = objs => {
    setValue( objs );
    setFieldValue( 'PresetTaskIds', objs.map( obj => obj.value ) );
  };

  return (
    <Select
      options={presetTasks.data?.map( ( task ) => ( {
        value: task.id,
        label: task.name,
      } ) )}
      onChange={onSelectChange}
      isMulti
      placeholder='Select tasks'
      className='select'
      classNamePrefix='select'
      value={value}
      maxMenuHeight={150}
    />
  );
};
