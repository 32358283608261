import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { sortEventsByEndTime, sortEventsByStartTime } from '../../../utils/sortings';
import styles from '../EmployeeAssignmentScreen.module.scss';
import { OfferHoverWindow } from './OfferHoverWindow';

export const EmployeeAcceptedJobs = ( { acceptedOffers, jobEvent } ) => {
  const start = useMemo( () => DateTime.fromISO( jobEvent.start ), [ jobEvent ] );
  const end = useMemo( () => DateTime.fromISO( jobEvent.end ), [ jobEvent ] );

  const beforeStatus = useMemo( () => {
    const nearOffers = acceptedOffers.filter( offer => {
      const diff = -DateTime.fromISO( offer.JobEvent.end ).diff( start, 'hours' ).hours;
      return diff >= 0 && diff <= 12;
    } );
    if ( nearOffers.length ) {
      const adjoiningOffers = nearOffers.filter( offer => {
        const diff = -DateTime.fromISO( offer.JobEvent.end ).diff( start, 'minutes' ).minutes;
        return diff >= 0 && diff <= 60;
      } );
      return adjoiningOffers.length
        ? {
            status: 'red',
            nearest: sortEventsByEndTime( adjoiningOffers.map( offer => offer.JobEvent ) )[0]
          }
        : {
            status: 'yellow',
            nearest: sortEventsByEndTime( nearOffers.map( offer => offer.JobEvent ) )[0]
          };
    } else {
      return { status: 'green' };
    }
  }, [ acceptedOffers, start ] );

  const afterStatus = useMemo( () => {
    const nearOffers = acceptedOffers.filter( offer => {
      const diff = DateTime.fromISO( offer.JobEvent.start ).diff( end, 'hours' ).hours;
      return diff >= 0 && diff <= 12;
    } );
    if ( nearOffers.length ) {
      const adjoiningOffers = nearOffers.filter( offer => {
        const diff = DateTime.fromISO( offer.JobEvent.start ).diff( end, 'minutes' ).minutes;
        return diff >= 0 && diff <= 60;
      } );
      return adjoiningOffers.length
        ? {
            status: 'red',
            nearest: sortEventsByStartTime(
              adjoiningOffers.map( offer => offer.JobEvent, 'desc' )
            )[0]
          }
        : {
            status: 'yellow',
            nearest: sortEventsByStartTime(
              nearOffers.map( offer => offer.JobEvent, 'desc' )
            )[0]
          };
    } else {
      return { status: 'green' };
    }
  }, [ acceptedOffers, start ] );

  return (
    <div className={styles['accepted-jobs']}>
      <div
        className={styles['before']}
        data-status={beforeStatus.status}
      >
        {beforeStatus.nearest && (
          <OfferHoverWindow jobEventId={beforeStatus.nearest.id} />
        )}
      </div>
      <div
        className={styles['after']}
        data-status={afterStatus.status}
      >
        {afterStatus.nearest && <OfferHoverWindow jobEventId={afterStatus.nearest.id} />}
      </div>
    </div>
  );
};
