import { Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Spinner } from '../../../components/Spinner';
import { useGetCostCentersQuery } from '../../../store/apis/costCentersApi';
import {
  useCreatePastJobEventMutation
} from '../../../store/apis/eventsApi';
import './AddPastEventForm.scss';
import {
  CostCenterField,
  DescriptionField,
  InternalCommentField,
  JobRoleField,
  LocationField,
  SkillsField,
  TaskField
} from './components';
import { EmployeesField } from './components/EmployeesField';
import { EndTimeField } from './components/EndTimeField';
import { StartTimeField } from './components/StartTimeField';
import { createOrderFormSchema } from './formValidation';

const AddPastEventForm = ( { onClose } ) => {
  const history = useHistory();

  const { data: costCenters } = useGetCostCentersQuery();

  const initStartTime = useMemo(
    () => DateTime.now().plus( { hour: 1 } ).startOf( 'hour' ),
    []
  );

  const initialValues = {
    costCenter: '',
    location: '',
    company: '',
    jobRole: '',
    task: '',
    startTime: initStartTime.toJSDate(),
    endTime: initStartTime.plus( { hours: 6 } ).toJSDate(),
    eventSkills: [],
    description: '',
    internalComment: '',
    open: false
  };
  const [ createPastJobEvent ] = useCreatePastJobEventMutation();

  if ( !costCenters ) return null;
  const onSubmit = ( values, { setSubmitting } ) => {
    createPastJobEvent( values )
      .unwrap()
      .then( ( ) => {
          setSubmitting( false );
          history.push( '/operations', { jobRole: values?.jobRole } );
      } )
      .catch( ( ) => {
        setSubmitting( false );
      } );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={createOrderFormSchema}
      validateOnMount={true}
    >
      {( { isSubmitting, isValid, dirty, values } ) => (
        <Form className='add-past-event pb-4'>
          {isSubmitting && (
            <div className='overlay'>
              <Spinner />
            </div>
          )}
          <div className='form__body'>
            <div className='form-group'>
              <EmployeesField />
            </div>
            <div className='form-group'>
              <LocationField />
              <CostCenterField />
            </div>
            <div className='form-group'>
              <JobRoleField />
              <TaskField />
            </div>
            <div className='d-flex align-items-baseline'>
              <StartTimeField eventTime={true} />
              <EndTimeField 
                eventDate={true}
                autoSelectDate={true}
                eventTime={true}
              />
            </div>
            <div className='form-group'>
              <SkillsField defaultValues={true} />
            </div>
            <div className='form-group'>
              <DescriptionField />
            </div>
            <div className='form-group'>
              <InternalCommentField />
            </div>
          </div>
          <div className='form-footer'>
            <div className='mt-4'>
              <div className='form-group align-items-center'>
                <button
                  className='btn btn--green'
                  type='submit'
                  disabled={
                    isSubmitting
                    || ( !isValid && !dirty )
                    || !values.location
                    || !values.employeeId
                  }
                >
                  Save
                </button>
                <button
                  className='btn btn--default'
                  onClick={onClose}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddPastEventForm;
