import moment from 'moment';
import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { useParams } from 'react-router-dom';
import { UserCard } from '../../../components/UserCard/UserCard';
import {
  useGetJobEventQuery,
  useSubmitJobLogMutation
} from '../../../store/apis/eventsApi';
import Default from './Default';
import { EditLogForm } from './EditLogForm';

const LogWaitingCustomer = () => {
  const { orderId } = useParams();

  const jobEvent = useGetJobEventQuery( orderId );
  const [ submitJobLog ] = useSubmitJobLogMutation();

  const [ rejectionComment, setRejectionComment ] = useState( '' );
  const [ showHistory, setShowHistory ] = useState( false );
  const [ isEditModalOpen, setEditModalOpen ] = useState( false );
  const [ isRejectModalOpen, setRejectModalOpen ] = useState( false );
  const [ editedValues, setEditedValues ] = useState();

  if ( !jobEvent.data ) return null;

  const order = jobEvent.data;
  const acceptedEmployee = order.JobOffer.AcceptedEmployee;
  const jobLog = editedValues || {
    Employee_Comment: order.JobLog.Employee_Comment,
    Employee_Rating: order.JobLog.Employee_Rating,
    Employee_Start: new Date( order.JobLog.Employee_Start ),
    Employee_End: new Date( order.JobLog.Employee_End ),
    Employee_LunchBreak: order.JobLog.Employee_LunchBreak,
    Customer_Rating: order.JobLog.Customer_Rating,
    Customer_Comment: order.JobLog.Customer_Comment,
  };
  const toggleEditModalOpen = open => () => setEditModalOpen( open );
  const toggleRejectModalOpen = open => () => setRejectModalOpen( open );
  const onEditValues = values => setEditedValues( values );
  const onRejectionCommentChange = event => setRejectionComment( event.target.value );

  const onAccept = () => {
    submitJobLog( {
      jobLog,
      id: orderId,
      decision: 'accept',
      comment: '',
    } );
  };

  const onReject = () => {
    submitJobLog( {
      jobLog,
      id: orderId,
      decision: 'reject',
      comment: rejectionComment,
    } );
  };

  return (
    <>
      <Default />
      <div className='row'>
        <div className='col-12'>
          <div className='white-shadow-box pb-3 mb-1'>
            <div className='info-cols'>
              <div className='info-col'>
                <span className='info-col__label'>Worker name</span>
                <UserCard
                  className='mt-2 justify-content-between'
                  infoBlockClassName='mr-2'
                  avatarPosition='last'
                  employee={acceptedEmployee}
                  nameBold
                />
              </div>
              <div className='info-col'>
                <span className='info-col__label'>Job role</span>
                <strong className='info-col__title'>{order.JobRole.name}</strong>
              </div>
              <div className='info-col'>
                <span className='info-col__label'>Date</span>
                <strong className='info-col__title'>
                  {moment( jobLog.updatedAt ).format( 'D. MMMM YYYY' )}
                </strong>
              </div>
            </div>
            <div className='info-cols mt-3'>
              <div className='info-col'>
                <span className='info-col__label'>Start time</span>
                <strong className='info-col__title'>
                  {moment( jobLog.Employee_Start ).format( 'HH:mm' )}
                </strong>
              </div>
              <div className='info-col'>
                <span className='info-col__label'>End time</span>
                <strong className='info-col__title'>
                  {moment( jobLog.Employee_End ).format( 'HH:mm' )}
                </strong>
              </div>
              <div className='info-col'>
                <span className='info-col__label'>Lunch break</span>
                <strong className='info-col__title'>
                  {jobLog.Employee_LunchBreak ? jobLog.Employee_LunchBreak : 0}
                </strong>
              </div>
            </div>
            <div className='info-cols mt-4'>
              <div className='info-col'>
                <span className='info-col__label mb-1'>Worker comment</span>
                <div className='form-control job-log-comment'>
                  {jobLog?.Employee_Comment}
                </div>
              </div>
            </div>
            <div className='info-cols mt-4'>
              <div className='info-col'>
                <span className='info-col__label mb-1'>Customer comment</span>
                <div className='form-control job-log-comment'>
                  {jobLog?.Customer_Comment}
                </div>
              </div>
            </div>
            {/* <div className="mt-3">
                            <button className="btn btn--green mr-12" disabled>Send message to Customer</button>
                        </div> */}
          </div>
        </div>
        <div className='col-12'>
          <div className='d-flex flex-wrap flex-md-nowrap justify-content-center align-items-center justify-content-md-between pt-4 pb-4'>
            <div className='btn-group pb-4 pb-md-0'>
              <div
                className='btn btn--green mr-12'
                onClick={onAccept}
              >
                Accept
              </div>
              <button
                className='btn btn--light-green mr-12'
                onClick={toggleEditModalOpen( true )}
              >
                Edit
              </button>
              <div
                className='btn btn--light-danger'
                onClick={toggleRejectModalOpen( true )}
              >
                Reject
              </div>
            </div>
            <button
              className='btn btn--default'
              onClick={() => setShowHistory( !showHistory )}
            >
              {showHistory ? 'Hide' : 'Show'} document history
            </button>
          </div>
        </div>
        {showHistory && (
          <div className='col-12'>
            <div className='white-shadow-box mb-1'>
              <div className='mb-3'>Document History</div>
              <div className='info-cols'>
                <div className='info-col'>
                  <span className='info-col__label'>Company created Job Order</span>
                </div>
                <div className='info-col'>
                  <span className='info-col__label'>
                    {moment( order?.createdAt ).format( 'MMMM D YYYY, h:mm a' )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <ReactModal
        isOpen={isEditModalOpen}
        appElement={document.body}
        style={{ content: { maxWidth: 800 } }}
      >
        <div className='modal-head'>
          <div className='modal-title'>Job log editing</div>
        </div>
        <div className='modal-body'>
          <EditLogForm
            onSubmit={onEditValues}
            closeModal={toggleEditModalOpen( false )}
            initialValues={jobLog}
          />
        </div>
      </ReactModal>
      <ReactModal
        isOpen={isRejectModalOpen}
        appElement={document.body}
        style={{ content: { maxWidth: 600 } }}
      >
        <div className='modal-head'>
          <div className='modal-title'>Why the job log is been rejected?</div>
        </div>
        <div className='modal-body'>
          <div className='form-group'>
            <div className='form-row'>
              <label htmlFor='rejection-comment'>Description</label>
              <textarea
                id='rejection-comment'
                value={rejectionComment}
                onChange={onRejectionCommentChange}
                className='form-control'
              />
            </div>
          </div>
        </div>
        <div className='form-footer mt-4 mb--4'>
          <div className='form-group'>
            <div className='form-row'>
              <button
                className='btn btn--green'
                onClick={onReject}
                disabled={!rejectionComment}
              >
                Send
              </button>
            </div>
            <div className='form-row'>
              <button
                className='btn btn--default'
                onClick={toggleRejectModalOpen( false )}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

export default LogWaitingCustomer;
